import React, { Component } from "react";

import { Container, Col, Row, Carousel } from "react-bootstrap";

import "../../node_modules/react-modal-video/css/modal-video.min.css";
import CarouselWhyChooseUs from "../blocks/whyChooseUsCarousel";
import ScrollAnimation from "react-animate-on-scroll";
import Global from "../Global.json";
import Skeleton from "react-loading-skeleton";
import swal from "sweetalert";

class WhyChooseUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      scrollDown: true,
      name: "",
      email: "",
      service: "",
      message: "",
      whyChooseUsSlider: [],
      whyChooseUsSliderLoader: true,
      partners: [],
      partnersLoader: true,
      whyChooseUsTitle: [],
      whyChooseUsTitleLoader: true,
    };
  }

  componentWillMount() {
    this.get_service_slider();
    this.get_partners();
    this.get_service_info();

    ///// to hide  scroll down button

    window.addEventListener("scroll", () => {
      this.setState({
        scrollDown: false,
      });
    });
    ///// to hide  scroll down button
  }

  /** Get service_slider Start  */

  get_service_slider() {
    fetch(Global.api_link + "get_service_slider", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        service_id: 3,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          whyChooseUsSlider: result.data,
          whyChooseUsSliderLoader: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /** Get service_slider End */

  /** Get service_info Start  */

  get_service_info() {
    fetch(Global.api_link + "get_service_info", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        service_id: 3,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          whyChooseUsTitle: result.data,
          whyChooseUsTitleLoader: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /** Get service_info End */

  /** Get partners Start  */

  get_partners() {
    fetch(Global.api_link + "get_partners", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        service_id: 3,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          partners: result.data,
          partnersLoader: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /** Get partners End */
  render() {
    return (
      <div className="WhyChooseUs">
        {/* why choose us carousel  */}
        <CarouselWhyChooseUs
          sliderItem={this.state.whyChooseUsSlider}
          sliderLoader={this.state.whyChooseUsSliderLoader}
        />

        {/* why choose us carousel  */}

        {/* solution company  */}
        <div className="queries">
          <Container fluid>
            <Row className="justify-content-space-between">
              {/*  solution company title  */}
              <Col xs={12} className="whyChooseUs-title-col">
                {this.state.whyChooseUsTitleLoader ? (
                  <Skeleton
                    height={50}
                    width={200}
                    style={{ float: "right" }}
                  />
                ) : (
                  <ScrollAnimation
                    animateIn="rotateInDownRight"
                    animateOnce={true}
                    duration={1}
                  >
                    <div className="ourServices-title">
                      <img src="/img/question.png" alt="l" />
                      <h3>{this.state.whyChooseUsTitle.name}</h3>
                    </div>
                  </ScrollAnimation>
                )}
              </Col>
              {/* end  solution company title  */}
              {/*  solution company description */}

              <Col lg={6}>
                <div className="ourServices-paragraph">
                  {this.state.whyChooseUsTitleLoader ? (
                    <Skeleton height={100} />
                  ) : (
                    <p>{this.state.whyChooseUsTitle.description}</p>
                  )}
                </div>
              </Col>
              {/* end  solution company description */}
            </Row>
            {/* manager company */}
            <div className="manager">
              <Row className="justify-content-center">
                <Col md={3}>
                  <ScrollAnimation
                    animateIn="rotateInDownRight"
                    animateOnce={true}
                    duration={2}
                  >
                    <div className="manager-company">
                      <img src="/img/training(1).png" alt="l" />
                    </div>
                    <p>المدراء</p>
                  </ScrollAnimation>
                </Col>
                <Col md={3}>
                  <ScrollAnimation
                    animateIn="rotateInDownRight"
                    animateOnce={true}
                    duration={2}
                  >
                    <div className="manager-company">
                      <img src="/img/search(5).png" alt="l" />
                    </div>
                    <p>التوظيف</p>
                  </ScrollAnimation>
                </Col>
                <Col md={3}>
                  <ScrollAnimation
                    animateIn="rotateInDownRight"
                    animateOnce={true}
                    duration={2}
                  >
                    <div className="manager-company">
                      <img src="/img/employees.png" alt="l" />
                    </div>
                    <p>الموظفين</p>
                  </ScrollAnimation>
                </Col>
              </Row>
            </div>
            {/* end  manager company */}
          </Container>
        </div>

        {/* end solution company  */}

        {/* Our clients section  */}
        <div className="ourClients" id="ourClients">
          <Container fluid>
            <Row className="justify-content-space-between">
              {/*  Our clients title  */}
              <Col xs={12} className="whyChooseUs-title-col">
                <ScrollAnimation
                  animateIn="rotateInDownRight"
                  animateOnce={true}
                  duration={1}
                >
                  <div className="ourServices-title">
                    <img src="/img/question.png" alt="l" />
                    <h3>عملائنا</h3>
                  </div>
                </ScrollAnimation>
              </Col>
              {/* end  Our clients title  */}

              <Col xs={12}>
                {/* Our clients carousel  */}

                <Carousel controls={false} interval={2000}>
                  {this.state.partnersLoader ? (
                    <Row>
             
                   
<Col xs={2}>

                        <Skeleton height={150} />
</Col>
<Col xs={2}>

                        <Skeleton height={150} />
</Col>
<Col xs={2}>

                        <Skeleton height={150} />
</Col>
<Col xs={2}>

                        <Skeleton height={150} />
</Col>
<Col xs={2}>

                        <Skeleton height={150} />
</Col>
<Col xs={2}>

                        <Skeleton height={150} />
</Col>
                  
                   
                    </Row>
                  ) : (
                    this.state.partners.map((item, index) => {
                      return (
                        <Carousel.Item>
                          <div className="ourClients-carousle-item" key={index}>
                            {item.map((img, Imgindex) => {
                              return (
                                <img
                                  src={Global.image_link + img.image}
                                  alt={img.title}
                                  key={Imgindex}
                                />
                              );
                            })}
                          </div>
                        </Carousel.Item>
                      );
                    })
                  )}
                </Carousel>
                {/* end Our clients carousel  */}
              </Col>
            </Row>
          </Container>
        </div>

        {/* end  Our clients section  */}

        <div className="whyChooseUs-contact">
          <Container fluid>
            <Row>
              {/* contact title  */}
              <Col xs={12}>
                <div className="contactUs-title" id="contactUs">
                  <ScrollAnimation
                    animateIn="rotateInDownRight"
                    animateOnce={true}
                    duration={1}
                  >
                    <h3>تواصل معنا</h3>
                  </ScrollAnimation>
                </div>
              </Col>
              {/* end contact title  */}

              <Col xl={6}>
                {/* contact form  */}
                <ScrollAnimation
                  animateIn="zoomIn"
                  animateOnce={true}
                  duration={1}
                >
                  <div className="contactUs-form ">
                    <Row>
                      <Col xl={12} md={4}>
                        <input
                          type="text"
                          placeholder="الاسم"
                          value={this.state.name}
                          onChange={(e) => {
                            this.setState({
                              name: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col xl={12} md={4}>
                        <input
                          type="text"
                          placeholder="البريد الالكتروني"
                          value={this.state.email}
                          className={
                            this.state.emailError ? "invalidEmail" : ""
                          }
                          onChange={(e) => {
                            this.setState({
                              email: e.target.value,
                              emailError: false,
                            });
                          }}
                        />
                      </Col>
                      <Col xl={12} md={4}>
                        <input
                          type="text"
                          placeholder="الخدمة"
                          value={this.state.service}
                          onChange={(e) => {
                            this.setState({
                              service: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col xl={12}>
                        <textarea
                          type="text"
                          placeholder="الرسالة"
                          value={this.state.message}
                          className={
                            this.state.messageError ? "invalidEmail" : ""
                          }
                          onChange={(e) => {
                            this.setState({
                              message: e.target.value,
                              messageError: false,
                            });
                          }}
                        />
                      </Col>

                      <Col xl={4} lg={12} md={12} className="text-center">
                        <button
                          className="know-more-button"
                          onClick={() => {
                            this.emailValidate();
                          }}
                        >
                          ارسال
                        </button>
                      </Col>
                      {/* contact social media */}
                      <Col xl={8} lg={12} md={12}>
                        <div className="contactUs-socialMedia">
                          <ul>
                            {this.props.socialMediaLoader
                              ? null
                              : this.props.social.map((item, index) => {
                                  return (
                                    <li key={index}>
                                      <a href={item.link}>
                                        <i className={"fa " + item.icon}></i>
                                      </a>
                                    </li>
                                  );
                                })}

                            {/* <li>
                              <a href="https://www.facebook.com">
                                <i className="fa fa-instagram"></i>
                              </a>
                            </li>
                            <li>
                              <a href="https://www.facebook.com">
                                <i className="fa fa-twitter"></i>
                              </a>
                            </li>
                            <li>
                              <a href="https://www.facebook.com">
                                <i className="fa fa-youtube"></i>
                              </a>
                            </li>
                            <li>
                              <a href="https://www.facebook.com">
                                <i className="fa fa-linkedin"></i>
                              </a>
                            </li> */}
                          </ul>
                        </div>
                      </Col>
                      {/* end contact social media */}
                    </Row>
                  </div>
                </ScrollAnimation>

                {/* end contact form  */}
              </Col>

              {/* contact us image  */}
              <Col sm={6} className="text-right contact-img-whyChooseUs">
                <img src="/img/krakenimages-Y5bvRlcCx8k-unsplash.png" alt="l" />
              </Col>
              {/* end contact us image  */}
            </Row>
          </Container>
          {/* points img  */}
          <img src="/img/asfour2.png" alt="l" className="header-point-img" />
          {/* end points img  */}
        </div>
      </div>
    );
  }

  /** Get contact Start  */

  contact() {
    fetch(Global.api_link + "contact", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        name: this.state.name,
        email: this.state.email,
        service: this.state.service,
        message: this.state.message,
        send_email: this.state.checked ? 1 : 0,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (this.state.emailError) {
          alert("email not valid");
        } else {
          swal({
            title: "تم ارسال الرسالة بنجاح",
            icon: "success",
            dangerMode: true,
            button: "موافق",
          }).then(()=>{
            this.setState({
              name: "",
              email: "",
              message: "",
              service: "",
              checked: false,
            })
          })}
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /** Get contact End */
  //// email validation function
  validateEmailRegular(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  emailValidate() {
    var value = this.state.email;
    if (!this.validateEmailRegular(value) && this.state.message === "") {
      this.setState({
        emailError: true,
        messageError: true,
      });
    } else if (!this.validateEmailRegular(value)) {
      this.setState({
        emailError: true,
      });
    } else if (this.state.message === "") {
      this.setState({
        messageError: true,
      });
    } else {
      this.setState(
        {
          emailError: false,
          // emailValidate: this.props.i18n.insertEmail,
        },
        () => {
          this.contact();
        }
      );
    }
  }
  //// end email validation function
}
export default WhyChooseUs;
