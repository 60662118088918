import React, { Component } from "react";
import ScrollAnimation from "react-animate-on-scroll";

import { Container, Col, Row } from "react-bootstrap";
import Global from "../Global.json";

import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/css/modal-video.min.css";
import ConnectUs from "../blocks/ConnectUs";
import CarouselWhyChooseUs from "../blocks/whyChooseUsCarousel";
import Skeleton from "react-loading-skeleton";

class Pachages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            scrollDown: true,
            consultationsInfo: {},
            consultations_specifications: [],
            consultations_specificationsLoader: true,
            packages: [],
            packagesLoader: true,
            packagesSlider: [],
            packagesSliderLoader: true,
            consultationsInfoLoader: true,
        };
    }

    componentDidMount() {
        this.get_service_info();
        this.consultations_specifications();
        this.get_consultations_packages();
        this.get_service_slider();

        ///// to hide  scroll down button

        window.addEventListener("scroll", () => {
            this.setState({
                scrollDown: false,
            });
        });
        ///// to hide  scroll down button
    }

    /** Get service_slider Start  */

    get_service_slider() {
        fetch(Global.api_link + "get_service_slider", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                service_id: 1,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    packagesSlider: result.data,
                    packagesSliderLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get service_slider End */

    /** Get service_info Start  */

    get_service_info() {
        fetch(Global.api_link + "get_service_info", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                service_id: 1,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    consultationsInfo: result.data,
                    consultationsInfoLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get service_info End */

    /** Get consultations_specifications Start  */

    consultations_specifications() {
        fetch(Global.api_link + "consultations_specifications", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                limit: 0,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    consultations_specifications: result.data,
                    consultations_specificationsLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get consultations_specifications End */

    /** Get consultations_packages Start  */

    get_consultations_packages() {
        fetch(Global.api_link + "get_consultations_packages", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                limit: 0,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    packages: result.data,
                    packagesLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get consultations_packages End */

    render() {
        return (
            <div className="Pachages">
                {/* why choose us carousel  */}

                <CarouselWhyChooseUs sliderItem={this.state.packagesSlider} sliderLoader={this.state.packagesSliderLoader} />

                {/* why choose us carousel  */}

                {/* queries section  */}

                <div className="personal-training" id="personal-training">
                    <div className="queries">
                        <Container fluid>
                            <Row className="justify-content-space-between">
                                {/*  queries title  */}
                                <Col xs={12}>
                                    <ScrollAnimation animateIn="rotateInDownRight" animateOnce={true} duration={1}>
                                        <div className="ourServices-title">
                                            {this.state.consultationsInfoLoader ? (
                                                <Skeleton height={50} width={200} />
                                            ) : (
                                                <>
                                                    <img src="/img/video-call.png" alt="l" />
                                                    <h3>{this.state.consultationsInfo.name}</h3>
                                                </>
                                            )}
                                        </div>
                                    </ScrollAnimation>
                                </Col>
                                {/* end  queries title  */}
                                {/*  queries description */}

                                <Col lg={6}>
                                    <div className="ourServices-paragraph">{this.state.consultationsInfoLoader ? <Skeleton height={50} /> : <p> {this.MyComponent(this.state.consultationsInfo.description)}</p>}</div>
                                </Col>
                                {/* end  queries description */}

                                {/*  queries types */}

                                <Col lg={7} className="z-index-top">
                                    <Row>
                                        {this.state.consultations_specificationsLoader ? (
                                            <>
                                                <Col lg={12}>
                                                    <Skeleton height={100} />
                                                </Col>
                                                <Col lg={12}>
                                                    <Skeleton height={100} />
                                                </Col>
                                                <Col lg={12}>
                                                    <Skeleton height={100} />
                                                </Col>
                                                <Col lg={12}>
                                                    <Skeleton height={100} />
                                                </Col>
                                            </>
                                        ) : (
                                            this.state.consultations_specifications.map((item, index) => {
                                                return (
                                                    <Col lg={12} key={index}>
                                                        <ScrollAnimation animateIn="bounceInRight" animateOnce={true} duration={2}>
                                                            <div className="packages-personal-training-item">
                                                                <div className="queries-info">
                                                                    <img src={Global.image_link + item.icon} alt="l" />
                                                                </div>
                                                                <div className="packages-personal-training-item-discription">
                                                                    <h6>{item.name}</h6>
                                                                    <p>
                                                                        {/* {item.text} */}
                                                                        {this.MyComponent(item.text)}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </ScrollAnimation>
                                                    </Col>
                                                );
                                            })
                                        )}
                                    </Row>
                                </Col>
                                {/* end  queries types */}

                                {/*  queries model video */}

                                <Col lg={4} sm={12}>
                                    {this.state.consultationsInfoLoader ? (
                                        <Skeleton height={400} />
                                    ) : (
                                        <>
                                            <div className="queries-video">
                                                <img src={Global.image_link + this.state.consultationsInfo.video_image} alt="l" />
                                                {/* <img onClick={() => this.setState({ isOpen: true })} src="/img/Group832.png" alt="l" /> */}
                                            </div>

                                            <ModalVideo
                                                channel="custom"
                                                autoplay
                                                isOpen={this.state.isOpen}
                                                url={this.state.consultationsInfo.video === null || this.state.consultationsInfo.video == "" ? this.state.consultationsInfo.video_link : Global.image_link + this.state.consultationsInfo.video}
                                                onClose={() => {
                                                    this.setState({ isOpen: false });
                                                }}
                                            />
                                        </>
                                    )}
                                </Col>
                                {/* end  queries model video */}
                            </Row>
                        </Container>
                        {/* points img  */}

                        <div className="queries-points-img">
                            <img src="/img/Group783.png" alt="l" />
                        </div>
                        {/* end points img  */}

                        {/* queries-circle  img  */}

                        <div className="queries-circle">
                            <img src="/img/Group831cut.png" alt="l" />
                        </div>
                        {/* end queries-circle  img  */}
                    </div>
                    <img src="/img/Ellipse522cut.png" alt="l" className="half-circle" />
                </div>
                {/* end queries section  */}

                {/* packages section */}
                <div className="packages-type">
                    <Container fluid>
                        <Row className="justify-content-center">
                            {/*  packages-type title  */}
                            <Col xs={12}>
                                <ScrollAnimation animateIn="rotateInDownRight" animateOnce={true} duration={1}>
                                    <div className="ourServices-title">
                                        <h3> الباقات</h3>
                                    </div>
                                </ScrollAnimation>
                            </Col>
                            {/* end  packages-type title  */}

                            {/* packages-type */}
                            <Col xl={10} lg={12} md={12} sm={10}>
                                <Row className="packages-type-row">
                                    {this.state.packagesLoader ? (
                                        <>
                                            <Col md={4}>
                                                <Skeleton height={400} />
                                            </Col>
                                            <Col md={4}>
                                                <Skeleton height={400} />
                                            </Col>
                                            <Col md={4}>
                                                <Skeleton height={400} />
                                            </Col>
                                        </>
                                    ) : (
                                        this.state.packages.map((item, index) => {
                                            return (
                                                <Col md={4}>
                                                    <ScrollAnimation animateIn="zoomIn" animateOnce={true} duration={2}>
                                                        <div className="packages-type-item-card">
                                                            <h5>{item.name}</h5>

                                                            <div className="div-timeOf_course">
                                                                <span>زمن الجلسة</span>
                                                                <span className="timeOf_course">{item.duration}</span>
                                                            </div>

                                                            {this.MyComponent(item.text)}

                                                            {item.price != 0 ? <div className="package-price">
                                                                <span>{item.price}</span>
                                                                <span>KD </span>
                                                            </div> : null}
                                                            <button className="know-more-button"
                                                                onClick={() =>
                                                                    //window.location.href = "https://api.whatsapp.com/message/5FQU53BCVME5O1?text= I Want to Register in  Course" + item.name
                                                                    window.location.href = "https://wa.me/+96597787321?text=I%20Want%20to%20Register%20in%20%20Course  " + " " + item.name
                                                                }> احجز الأن</button>
                                                        </div>
                                                    </ScrollAnimation>
                                                </Col>
                                            );
                                        })
                                    )}
                                </Row>
                            </Col>

                            {/* packages-type */}
                        </Row>
                    </Container>
                </div>
                {/* end packages section */}
            </div>
        );
    }
    /** Convert Content to HTML */
    MyComponent(desc) {
        return <div dangerouslySetInnerHTML={this.createMarkup(desc)} />;
    }
    createMarkup(desc) {
        return { __html: desc };
    }
    /** Convert Content to HTML End*/
}
export default Pachages;
