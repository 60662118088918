import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ConnectUs from "../blocks/ConnectUs";
import ScrollAnimation from "react-animate-on-scroll";
import { Modal } from "react-bootstrap";
import Global from "../Global.json";
import { FacebookShareButton, WhatsappIcon, WhatsappShareButton, FacebookIcon, EmailShareButton, EmailIcon, TwitterIcon, TwitterShareButton } from "react-share";
import Skeleton from "react-loading-skeleton";
class ArticleDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            articleDetails: [],
            articleDetailsLoader: true,
            articleData: [],
            articleDataLoader: true,
            showShears: false,
        };
    }

    componentWillMount() {
        this.get_blogsDetails();
        this.get_blogs();
    }

    /** Get blogsDetails Start  */

    get_blogsDetails() {
        fetch(Global.api_link + "get_blogs_details", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                id: this.props.match.params.id,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    articleDetails: result.data,
                    articleDetailsLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get blogsDetails End */

    /** Get blogs Start  */

    get_blogs() {
        fetch(Global.api_link + "get_blogs", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                limit: 5,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    articleData: result.Random,
                    articleDataLoader: false,
                    // MostLikedArticle: result.MostLiked,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get blogs End */

    render() {
        return (
            <div className="ArticleDetails">
                <Container fluid>
                    <Row>
                        <Col sm={8}>
                            <div className="article-info">
                                {/* main article img  */}
                                <ScrollAnimation animateIn="zoomIn" animateOnce={true} duration={2}>
                                    <img
                                        className="mainArticleImg"
                                        // src="/img/c1754209c390f82db32c1015ddff62802.png"
                                        src={Global.image_link + this.state.articleDetails.image}
                                        alt="l"
                                    />
                                </ScrollAnimation>
                                {/* main article img  en */}

                                {/* article header section  */}
                                <div className="article-info-header">
                                    {/* article name  */}
                                    <h2> {this.state.articleDetails.title}</h2>
                                    {/* end article name  */}
                                    <div className="dateLikeShaer">
                                        {/* time to read and date  */}
                                        <div className="timeToRead-article-info">
                                            <span>{this.state.articleDetails.date}</span>
                                            <span>تحتاج {this.state.articleDetails.time_to_read} للقراءة </span>
                                        </div>
                                        {/* time to read and date end */}

                                        {/* like and shear section  */}
                                        <div className="likeAndShear">
                                            <div className="shareDiv">
                                                <img
                                                    src="/img/share.svg"
                                                    alt="l"
                                                    className="shearImg"
                                                    onClick={() => {
                                                        this.setState({
                                                            showShears: true,
                                                        });
                                                    }}
                                                />
                                            </div>
                                            {/* <img src="/img/Group58865.png" alt="l" /> */}
                                            <div
                                                className="numImgLike"
                                                onClick={() => {
                                                    this.addLike(this.state.articleDetails.id);
                                                }}
                                            >
                                                <img src="/img/like(2).png" alt="l" />
                                                <span>{this.kFormatter(this.state.articleDetails.likes)}</span>
                                            </div>
                                        </div>
                                        {/* like and shear section  end */}

                                        {/* modal shear article start */}
                                        <Modal className="shareModal" show={this.state.showShears} onHide={() => this.setState({ showShears: false })}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>مشاركة مع الاصدقاء</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="ShareRow">
                                                    {/* <h5>مشاركة </h5> */}
                                                    <ul>
                                                        <a>
                                                            <li>
                                                                <FacebookShareButton
                                                                    url={Global.share_link + this.props.match.params.id}
                                                                    separator=":: "
                                                                    // title={this.state.Details.title}
                                                                    quote={"Look at this amazing offer from Sooq"}
                                                                >
                                                                    <FacebookIcon size={32} round />
                                                                </FacebookShareButton>
                                                            </li>
                                                        </a>
                                                        <a>
                                                            <li>
                                                                <WhatsappShareButton
                                                                    url={Global.share_link + this.props.match.params.id}
                                                                    separator=":: "
                                                                    // title={this.state.Details.title}
                                                                >
                                                                    <WhatsappIcon size={32} round />
                                                                </WhatsappShareButton>
                                                            </li>
                                                        </a>
                                                        <a>
                                                            <li>
                                                                <TwitterShareButton
                                                                    url={Global.share_link + this.props.match.params.id}
                                                                    // subject={this.state.Details.title}
                                                                    body="I want you to see this offer from Sooq "
                                                                    separator=":: "
                                                                    // title={this.state.Details.title}
                                                                >
                                                                    <TwitterIcon size={32} round />
                                                                </TwitterShareButton>
                                                            </li>
                                                        </a>

                                                        <a>
                                                            <li>
                                                                <EmailShareButton
                                                                    url={Global.share_link + this.props.match.params.id}
                                                                    // subject={this.state.Details.title}
                                                                    body="I want you to see this offer from Sooq "
                                                                    separator=":: "
                                                                    // title={this.state.Details.title}
                                                                >
                                                                    <EmailIcon size={32} round />
                                                                </EmailShareButton>
                                                            </li>
                                                        </a>
                                                        <a title="Copy Link">
                                                            <li onClick={() => this.copyPageUrl(Global.share_link + this.props.match.params.id)}>
                                                                <img src="/img/copy.png"></img>
                                                            </li>
                                                        </a>
                                                    </ul>
                                                </div>
                                            </Modal.Body>
                                        </Modal>
                                        {/* modal shear article end */}
                                    </div>
                                </div>
                                {/* article header section end  */}

                                {/* article body section  */}
                                <div className="article-info-body">
                                    <p>{this.MyComponent(this.state.articleDetails.descr)}</p>
                                </div>

                                {/* article body section  end */}
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div className="read-more">
                                {/* read more title  */}

                                <h3>اقرأ ايضا</h3>

                                {/* read more title end */}
                                <ul>
                                    {this.state.articleDataLoader ? (
                                        <>
                                            <li>
                                                <Skeleton height={100} />
                                            </li>
                                            <li>
                                                <Skeleton height={100} />
                                            </li>
                                            <li>
                                                <Skeleton height={100} />
                                            </li>
                                        </>
                                    ) : (
                                        this.state.articleData.map((item, index) => {
                                            return (
                                                <li>
                                                    {/* article  title */}
                                                    <h4>{item.title}</h4>
                                                    {/* article  title end */}

                                                    {/* article  desc */}
                                                    <a href={"/ArticleDetails/" + item.id}>{this.MyComponent(item.descr)}</a>
                                                    {/* article  desc end */}

                                                    {/* time to read and date  */}
                                                    <div className="timeToRead-article-info-body">
                                                        <span>{item.date}</span>
                                                        <span>تحتاج {item.time_to_read} للقراءة </span>
                                                    </div>
                                                    {/* time to read and date end */}
                                                </li>
                                            );
                                        })
                                    )}
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

    /** Add Like Start  */

    addLike(id) {
        fetch(Global.api_link + "like_blog", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                id: id,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.get_blogsDetails();
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Add Like End */

    /** Convert Content to HTML */
    MyComponent(desc) {
        return <div dangerouslySetInnerHTML={this.createMarkup(desc)} />;
    }
    createMarkup(desc) {
        return { __html: desc };
    }
    /** Convert Content to HTML End*/

    // format num fun start
    kFormatter(num) {
        return Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k" : Math.sign(num) * Math.abs(num);
    }
    // format num fun end

    // copy link url
    async copyPageUrl(url) {
        try {
            await navigator.clipboard.writeText(url);
            console.log("Page URL copied to clipboard");
        } catch (err) {
            console.error("Failed to copy: ", err);
        }
    }
    // copy link url
}

export default ArticleDetails;
