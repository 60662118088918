import React, { Component } from "react";
import ScrollAnimation from "react-animate-on-scroll";

import { Container, Col, Row, Carousel } from "react-bootstrap";
import Global from "../Global.json";

import ConnectUs from "../blocks/ConnectUs";
import Skeleton from "react-loading-skeleton";

class BookPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            scrollDown: true,
            showMore: false,
            books: [],
            booksLoader: true,
            activeSlide: null,
        };
    }

    componentDidMount() {
        this.get_books();

        ///// to hide  scroll down button

        window.addEventListener("scroll", () => {
            this.setState({
                scrollDown: false,
            });
        });
        ///// to hide  scroll down button
    }

    /** Get books Start  */

    get_books() {
        fetch(Global.api_link + "get_books", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    books: result.data,
                    booksLoader: false,
                    descrBook: this.MyComponent(result.data[this.state.activeSlide != null ? this.state.activeSlide : parseInt(this.props.match.params.Index)].descr),
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get books End */
    render() {
        return (
            <div className="BookPage">
                {/*  book section  */}
                <div className="books">
                    <Container fluid>
                        <Row>
                            <Col lg={7}>
                                {/* book name  */}
                                <Col xs={12}>
                                    <ScrollAnimation animateIn="rotateInDownRight" animateOnce={true} duration={1}>
                                        <div className="ourServices-title">
                                            <img src="/img/book(1).png" alt="l" />
                                            <h3>الكتب</h3>
                                        </div>
                                    </ScrollAnimation>
                                </Col>
                                {/* end book name  */}

                                {/* book detailes */}

                                <Col xs={12}>
                                    <div className="books-discription">
                                        {this.state.booksLoader ? (
                                            <div className="books-title">
                                                <Skeleton height={50} width={100} />
                                            </div>
                                        ) : (
                                            <div className="books-title">
                                                <h3>{this.state.books[this.state.activeSlide != null ? this.state.activeSlide : parseInt(this.props.match.params.Index)].name}</h3>
                                            </div>
                                        )}
                                        <div className="ourServices-paragraph">
                                            {this.state.booksLoader ? (
                                                <Skeleton height={200} />
                                            ) : (
                                                <>
                                                    <span>{this.MyComponent(this.state.books[this.state.activeSlide != null ? this.state.activeSlide : parseInt(this.props.match.params.Index)].descr)}</span>
                                                    <>

                                                    <a  href={this.state.books[this.state.activeSlide != null ? this.state.activeSlide : parseInt(this.props.match.params.Index)].link} target="blank"  className="Uploadbook">حمّل نسختك الآن</a>
                                                        {/* {this.state.showMore ? (
                        <>
                          <span>
                          <LinesEllipsis
                            text={this.MyComponent(this.state.books[this.state.activeSlide != null ? this.state.activeSlide :  parseInt(this.props.match.params.Index)].descr)}
                            maxLine={4}
                            ellipsis="..."
                            trimRight
                            basedOn="letters"
                          />
                          {/* {this.MyComponent(this.state.books[this.state.activeSlide != null ? this.state.activeSlide :  parseInt(this.props.match.params.Index)].descr)} 
                          </span>
                        </>
                      ) : null} */}
                                                    </>
                                                </>
                                            )}
                                            {/* <p
                        onClick={() => {
                          this.setState({
                            showMore: true,
                          });
                        }}
                        className='showMoreParagraph'
                      >
                        {this.state.showMore ? "" : "اقرأ المزيد"}
                      </p> */}
                                        </div>
                                    </div>
                                </Col>
                                {/* book detailes */}
                            </Col>
                            {/* books-carousel */}
                            <Col lg={5}>
                                <div className="books-carousel">
                                    <Carousel
                                        defaultActiveIndex={parseInt(this.props.match.params.Index)}
                                        interval={null}
                                        onSlide={(index) => {
                                            this.setState({
                                                activeSlide: index,
                                                showMore: false,
                                            });
                                        }}
                                    >
                                        {this.state.booksLoader ? (
                                            <Skeleton height={300} width={200} />
                                        ) : (
                                            this.state.books.map((item, index) => {
                                                return (
                                                    <Carousel.Item key={index}>
                                                        <a href={item.link} target="blank" className="ourClients-carousle-item">
                                                            <img src={Global.image_link + item.image} alt="First slide" />
                                                        </a>
                                                    </Carousel.Item>
                                                );
                                            })
                                        )}
                                    </Carousel>
                                </div>
                            </Col>
                            {/* end books-carousel */}
                        </Row>
                    </Container>

                    {/* Ellipse img  */}
                    <img src="/img/Ellipse522cut.png" alt="l" className="bookPage-circle-img" />
                    {/* end Ellipse img  */}

                    {/* circle  img  */}

                    <div className="queries-circle">
                        <img src="/img/Group831cut.png" alt="l" />
                    </div>
                    {/* end circle  img  */}
                </div>
                {/*  end  book section  */}
            </div>
        );
    }
    /** Convert Content to HTML */
    MyComponent(desc) {
        return <div dangerouslySetInnerHTML={this.createMarkup(desc)} />;
    }
    createMarkup(desc) {
        return { __html: desc };
    }
    /** Convert Content to HTML End*/
}
export default BookPage;
