import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import Global from "../Global.json";
import swal from "sweetalert";

class exam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            scrollDown: true,
            showMore: false,
            rules: [],
            booksLoader: false,
            activeSlide: null,
            selected: false,
            ExamImage : "", 
        };

    }

    componentDidMount() {
        this.get_rules()
        ///// to hide  scroll down button
        window.addEventListener("scroll", () => {
            this.setState({
                scrollDown: false,
            });
        });
        ///// to hide  scroll down button


        fetch(Global.api_link + "get_settings", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                console.log(result.data.exam_image)
                this.setState({
                    ExamImage: result.data.exam_image,
                    ExamQuote: result.data.exam_quote,
                    logoLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });

    }

    /** Get  rules Start  */
    get_rules() {
        fetch(Global.api_link + "getExamRules", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    rules: result.data,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }
    /** Get rules End */

    Start() {
        window.location.href = "/Questions"
    }


    render() {
        return (
            <div className="BookPage">
                {/*  Exam section  */}
                <div className="books">
                    <Container fluid>
                        <Row>
                            <Col lg={7}>
                                {/* Exam Details */}
                                <Col xs={12}>
                                    <div className="books-discription">
                                        <div className="MeasureText">
                                            <p>{this.state.ExamQuote}</p>

                                            <br /><br />
                                            

                                            <div className="ApproveTermTest">
                                                {this.state.rules.map((rule, index) => {
                                                    return (
                                                        <>
                                                            <label class="container"> &#8668; {rule.rule}
                                                            </label>
                                                        </>
                                                    )
                                                })
                                                }

                                                <div className="submitButt">
                                                    <a class="buttonSub" style={{ verticalAlign: "left" }} onClick={() => this.Start()}><span>ابدأ الاختبار  </span></a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                {/* Exam Details */}
                            </Col>
                            {/* Exam Img */}
                            <Col lg={5}>
                                <div className="ExamImg">
                                    <img src={Global.image_link + this.state.ExamImage} alt="l" />
                                </div>
                            </Col>
                            {/* Exam Img  */}
                        </Row>
                    </Container>



                </div>
                {/*  end  exam section  */}
            </div>
        );
    }
}
export default exam;
