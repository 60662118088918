import React, { Component } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Global from "../Global.json";

import { Container, Col, Row } from "react-bootstrap";
import ConnectUs from "../blocks/ConnectUs";
import Skeleton from "react-loading-skeleton";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
class Publications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            blogs: [],
            blogsLoader: true,
        };
    }
    componentWillMount() {
        this.get_blogs();
    }
    /** Get blogs Start  */

    get_blogs() {
        fetch(Global.api_link + "get_blogs", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    blogs: result.data,
                    blogsLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get blogs End */
    render() {
        return (
            <div className="Publications">
                <div className="contactUs publications-section">
                    <Container fluid>
                        <Row>
                            {/* Publications title  */}
                            <Col xs={12}>
                                <ScrollAnimation animateIn="rotateInDownRight" animateOnce={true} duration={1}>
                                    <div className="ourServices-title">
                                        <img src="/img/book(1).png" alt="l" />
                                        <h3>المنشورات </h3>
                                    </div>
                                </ScrollAnimation>
                            </Col>
                            {/* end Publications title  */}
                        </Row>
                        <Row className="justify-content-center">
                            {/* publications item */}
                            <Col xl={10} lg={12}>
                                <div className="publications-item">
                                    <div className="titleOfPublication">
                                        <h6>عنوان المنشور</h6>
                                        <p>
                                            <span>فلتر</span>
                                            <div className="filter">
                                                <img src="/img/filter(1).png" alt="f" />
                                                <DatePicker
                                                    className="form-control"
                                                    // selected={this.state.startDate}

                                                    showYearPicker
                                                    dateFormat="yyyy"
                                                />
                                            </div>
                                        </p>
                                    </div>
                                    {this.state.blogsLoader ? (
                                        <ul>
                                            <li>
                                                <Skeleton height={40} />
                                            </li>
                                            <li>
                                                <Skeleton height={40} />
                                            </li>
                                            <li>
                                                <Skeleton height={40} />
                                            </li>
                                            <li>
                                                <Skeleton height={40} />
                                            </li>
                                        </ul>
                                    ) : (
                                        <ul>
                                            {this.state.blogs.map((item, index) => {
                                                return (
                                                    <li>
                                                        <a href={item.link}>
                                                            <span>{this.MyComponent(item.descr)}</span>
                                                            <span> {moment(item.year).format("YYYY")}</span>
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    )}
                                </div>
                            </Col>
                            {/*  end publications item */}
                        </Row>
                    </Container>
                    {/* Ellipse img  */}
                    <img src="/img/Ellipse522cut.png" alt="l" className="header-point-img" />
                    {/* end Ellipse img  */}
                </div>
            </div>
        );
    }

    /** Convert Content to HTML */
    MyComponent(desc) {
        return <div dangerouslySetInnerHTML={this.createMarkup(desc)} />;
    }
    createMarkup(desc) {
        return { __html: desc };
    }
    /** Convert Content to HTML End*/
}
export default Publications;
