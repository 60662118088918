import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import { Container, Col, Row, Navbar, Nav, NavDropdown } from "react-bootstrap";
////
import { slide as Menu } from "react-burger-menu";
import ScrollAnimation from "react-animate-on-scroll";
import ScrollToTop from "react-scroll-to-top";
import Global from "../Global.json";

////
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollDown: true,
            activeNavBorder: 0,
            services: [],
            logo: "",
        };
    }
  
    componentDidMount() {
        if (window.location.pathname === "/WorkExperience") {
            this.setState({
                activeNavBorder: 1,
            });
        } else if (window.location.pathname === "/Article") {
            this.setState({
                activeNavBorder: 3,
            });
        } else if (window.location.pathname === "/BookPage/0") {
            this.setState({
                activeNavBorder: 4,
            });
        } else if (window.location.pathname === "/contactUs") {
            this.setState({
                activeNavBorder: 5,
            });
        } else if (window.location.pathname === "/Tearms/Common_questions") {
            this.setState({
                activeNavBorder: 6,
            });
        } else if (window.location.pathname === "/exam") {
            this.setState({
                activeNavBorder: 8,
            });
        }
        this.get_main_services();
        this.get_settings();
        ///// to hide  scroll down button

        window.addEventListener("scroll", () => {
            this.setState({
                scrollDown: false,
            });
        });
        ///// to hide  scroll down button
    }

    /** Get  settings Start  */

    get_settings() {
        fetch(Global.api_link + "get_settings", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    logo: result.data.logo,
                    logoLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get settings End */

    /** Get main services Start  */

    get_main_services() {
        fetch(Global.api_link + "get_main_services", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    services: result.services,
                    MainServiceLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get get_main_services End */

    render() {
        const { location } = this.props;
        return (
            <>
                <div className="header">
                    {/* logo section */}
                    <a href="/">{this.state.logo ? <img src={Global.image_link + this.state.logo} alt="1" className="header-logo" /> : null}</a>

                    {/* end logo section */}

                    {/* navbar web section  */}
                    <Container fluid>
                        <Row className="Row-header no_pad ">
                            <Col lg={12} xs={12} className="navbar-web">
                                {/* nav section */}
                                <Navbar expand="lg" className="no_pad ">
                                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                    <Navbar.Collapse id="basic-navbar-nav">
                                        <Nav className="">
                                            <ScrollAnimation animateOnce={true} animateIn="zoomIn" duration={2} offset={0}>
                                                <Nav.Link href="/WorkExperience" className={this.state.activeNavBorder === 1 ? "active-border-bottom" : ""}>
                                                    السيرة الذاتية
                                                </Nav.Link>
                                            </ScrollAnimation>

                                            <ScrollAnimation animateOnce={true} animateIn="zoomIn" duration={2} offset={0} delay={100}>
                                                <Nav.Link href="/exam" className={this.state.activeNavBorder === 8 ? "active-border-bottom" : ""}>
                                                مقياس العصفور
                                                </Nav.Link>
                                            </ScrollAnimation>

                                            <ScrollAnimation animateOnce={true} animateIn="zoomIn" duration={2} offset={0} delay={200}>
                                                <NavDropdown title="الخدمات" id="nav-dropdown">
                                                    {this.state.services.map(({ name, id }, index) => {
                                                        return (
                                                            <>
                                                                <NavDropdown.Item eventKey="4.1" href={+id === 1 ? "/Packages" : +id === 2 ? "/training" : +id === 3 ? "/WhyChooseUs" : "/"}>
                                                                    {name}
                                                                </NavDropdown.Item>

                                                                {!(index === this.state.services.length - 1) && <NavDropdown.Divider />}
                                                            </>
                                                        );
                                                    })}

                                                    {/* <NavDropdown.Item eventKey="4.2" href="/Packages">
                                                        الاستشارات
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Divider />
                                                    <NavDropdown.Item eventKey="4.3" href="/WhyChooseUs">
                                                        حلول الشركات
                                                    </NavDropdown.Item> */}
                                                </NavDropdown>
                                            </ScrollAnimation>

                                            <ScrollAnimation animateOnce={true} animateIn="zoomIn" duration={2} offset={0} delay={300}>
                                                <Nav.Link href="/BookPage/0" className={this.state.activeNavBorder === 4 ? "active-border-bottom" : ""}>
                                                    المنشورات
                                                </Nav.Link>
                                            </ScrollAnimation>

                                            <ScrollAnimation animateOnce={true} animateIn="zoomIn" duration={2} offset={0} delay={400}>
                                                <Nav.Link href="/Tearms/Common_questions" className={this.state.activeNavBorder === 6 ? "active-border-bottom" : ""}>
                                                    الاسئلة الشائعة
                                                </Nav.Link>
                                            </ScrollAnimation>
                                            <ScrollAnimation animateOnce={true} animateIn="zoomIn" duration={2} offset={0} delay={500}>
                                                <Nav.Link href="/contactUs" className={this.state.activeNavBorder === 5 ? "active-border-bottom" : ""}>
                                                    التواصل
                                                </Nav.Link>
                                            </ScrollAnimation>

                                            <ScrollAnimation animateOnce={true} animateIn="zoomIn" duration={2} offset={0} delay={600}>
                                                <Nav.Link href="/Article" className={this.state.activeNavBorder === 3 ? "active-border-bottom" : ""}>
                                                    المدونة
                                                </Nav.Link>
                                            </ScrollAnimation>

                                        </Nav>
                                    </Navbar.Collapse>
                                </Navbar>
                            </Col>
                            {/* end nav section */}
                        </Row>

                        {/*  mobile push menu  */}
                        <div className="mobile-push-menu">
                            <Menu right isOpen={false} customBurgerIcon={<i className="fa fa-bars"></i>} customCrossIcon={<i className="fa fa-times croosIconMenu"></i>}>
                                <img src="/img/Group776.png" alt="logo" />

                                <a id="home" className="menu-item" href="/WorkExperience">
                                    السيرة الذاتية
                                </a>

                                <a id="exam" className="menu-item" href="/exam">
                                مقياس العصفور
                                </a>

                                {/* <a id="about" className="menu-item" href="/training">
                    الخدمات
                  </a> */}
                                <NavDropdown title="الخدمات" id="nav-dropdown" className="menu-item">
                                    <NavDropdown.Item eventKey="4.1" href="/training">
                                        التدريب
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item eventKey="4.2" href="/Packages">
                                        الاستشارات
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item eventKey="4.3" href="/WhyChooseUs">
                                        حلول الشركات
                                    </NavDropdown.Item>
                                </NavDropdown>

                                <a id="contact" className="menu-item" href="/BookPage/0">
                                    المنشورات
                                </a>


                                <a id="Common_questions" className="menu-item" href="/Tearms/Common_questions">
                                    الاسئلة الشائعة
                                </a>

                                <a id="contact" className="menu-item" href="/contactUs">
                                    التواصل
                                </a>

                                <a id="contact" className="menu-item" href="/Article">
                                    المدونة
                                </a>

                                {/* <a onClick={ this.showSettings } className="menu-item--small" href="">Settings</a> */}
                            </Menu>
                        </div>

                        {/* end  mobile push menu  */}

                        {/* points img  */}
                        <img src="/img/left.png" alt="l" className="header-point-img" />
                        {/* end points img  */}

                        {/*  social  media   */}
                        <div className="MediaUL">
                            <ul>
                                {this.props.socialMedia.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            <a href={item.link}>
                                                <i className={"fa " + item.icon}></i>
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        {/*end social media  */}
                    </Container>

                    <ScrollToTop smooth />

                    {this.state.scrollDown ? (
                        <a href="#contactUs" className="scroll-icon">
                            <img src="/img/Icon feather-arrow-down1.png" alt="1" />
                        </a>
                    ) : null}
                </div>
            </>
        );
    }
}
export default Header;
