import React, { Component } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Global from "../Global.json";

import { Container, Col, Row } from "react-bootstrap";
import swal from "sweetalert";

class ConnectUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      name: "",
      email: "",
      message: "",
      service: "",
      socialMedia: [],
      socialMediaLoader: true,
    };
  }

  componentWillMount() {
    this.get_media();
  }

  /** Get media Start  */

  get_media() {
    fetch(Global.api_link + "get_media", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          socialMedia: result.data,
          socialMediaLoader: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /** Get contact End */

  render() {
    return (
      // contact us sction
      <div className={window.location.pathname =='/contactUs' ? ' contactUs full-height' :  "contactUs"} id="contactUs">
        <Container fluid>
          <Row>
            {/* contact title  */}
            <Col xs={12}>
              <div className="contactUs-title">
                <ScrollAnimation
                  animateIn="rotateInDownRight"
                  animateOnce={true}
                  duration={1}
                >
                  <h3>تواصل معنا</h3>
                </ScrollAnimation>
              </div>
            </Col>
            {/* end contact title  */}

            {/* contact form  */}
            <Col lg={10} md={10}>
              <ScrollAnimation
                animateIn="zoomIn"
                animateOnce={true}
                duration={2}
              >
                <div className="contactUs-form ">
                  <Row>
                    <Col md={4}>
                      <input
                        type="text"
                        placeholder="الاسم"
                        value={this.state.name}
                        onChange={(e) => {
                          this.setState({
                            name: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col md={4}>
                      <input
                        type="text"
                        placeholder="البريد الالكتروني"
                        value={this.state.email}
                        className={this.state.emailError ? "invalidEmail" : ""}
                        onChange={(e) => {
                          this.setState(
                            {
                              email: e.target.value,
                              emailError:false
                            }
                           
                          );
                        }}
                      />
                    </Col>
                    <Col md={4}>
                      <input
                        type="text"
                        placeholder="الخدمة"
                        value={this.state.service}
                        onChange={(e) => {
                          this.setState({
                            service: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col md={12}>
                      <textarea
                        type="text"
                        placeholder="الرسالة"
                        value={this.state.message}
                        className={this.state.messageError ? "invalidEmail"  : ''}
                        onChange={(e) => {
                          this.setState({
                            message: e.target.value,
                            messageError:false
                          });
                        }}
                      />
                    </Col>
                    <Col md={12}>
                      {/* <input type='checkbox'  className='radio-input'/> */}
                      <label className="container-input">
                        <input
                          type="checkbox"
                          className="radio-input"
                          value={this.state.checked}
                          checked={this.state.checked}
                          onChange={() => {
                            this.setState({
                              checked: !this.state.checked,
                            });
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <span>
                        اوافق على استقبال رسائل الكترونية من موقع الدكتور محمد
                        عصفور (الموقع الرسمي )
                      </span>
                      <button
                        className="know-more-button"
                        onClick={() => {
                        
                          this.emailValidate();
                        }}
                      >
                        ارسال
                      </button>
                    </Col>
                  </Row>
                </div>
              </ScrollAnimation>
            </Col>
            {/* end contact form  */}
            {/* contact social media */}
            <Col lg={2} md={2}>
              {this.state.socialMedia.length < 1 ? null : (

             
              <ScrollAnimation
                animateIn="zoomIn"
                animateOnce={true}
                duration={2}
              >
                <div className="contactUs-socialMedia">
                  <ul>
                    {this.state.socialMediaLoader
                      ? null
                      : this.state.socialMedia.map((item, index) => {
                          return (
                            <li key={index}>
                              <a href={item.link}>
                                <i className={"fa " + item.icon}></i>
                              </a>
                            </li>
                          );
                        })}
                  </ul>
                </div>
              </ScrollAnimation>
               )}
            </Col>
            {/* end contact social media */}
          </Row>
        </Container>
        {/* points img  */}
        <img src="/img/asfour2.png" alt="l" className="header-point-img" />
        {/* end points img  */}
      </div>
      // end contact us sction
    );
  }
  /** Get contact Start  */

  contact() {
    fetch(Global.api_link + "contact", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        name: this.state.name,
        email: this.state.email,
        service: this.state.service,
        message: this.state.message,
        send_email: this.state.checked ? 1 : 0,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        swal({
          title: "تم ارسال الرسالة بنجاح",
          icon: "success",
          dangerMode: true,
          button: "موافق",
        }).then(()=>{
          this.setState({
            name: "",
            email: "",
            message: "",
            service: "",
            checked: false,
          })
        })
        })
      .catch((error) => {
        console.error(error);
      });
  }

  /** Get contact End */

  //// email validation function
  validateEmailRegular(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  emailValidate() {
    var value = this.state.email;
    if (!this.validateEmailRegular(value) && this.state.message === '' ) {
      this.setState({
        emailError: true,
        messageError : true,

      });
    } else if (!this.validateEmailRegular(value)){
        
      this.setState({
        emailError: true,

      })
    } else if (this.state.message === ''){
        
        this.setState({
          messageError : true,
        })
      } else {
      this.setState({
        emailError: false,
        // emailValidate: this.props.i18n.insertEmail,
      },()=>{
        this.contact();
      });
    }
  }
  //// end email validation function
}
export default ConnectUs;
