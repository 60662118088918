import React, { Component } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Global from "../Global.json";

import { Carousel } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

class CarouselWhyChooseUs extends Component {
  render() {
    return (
      <div className="CarouselWhyChooseUs">
        {this.props.sliderLoader ? (
          <Skeleton height={400} />
        ) : (
          <Carousel>
            {this.props.sliderItem.map((item, index) => {
              return (
                <Carousel.Item key={index}>
                  <div className="box-shadow-bg"></div>
                  <img
                    className="d-block w-100"
                    src={Global.image_link + item.image}
                    alt="First slide"
                  />
                  <ScrollAnimation
                    animateIn="pulse"
                    animateOnce={true}
                    className="whyChooseUs-carousel-title"
                    duration={1}
                    offset={0}
                  >
                    {/* <p className="whyChooseUs-carousel-title"> */}

                    {this.MyComponent(item.text)}
                    {/* </p> */}
                  </ScrollAnimation>
                  <Carousel.Caption>
                    <p>{item.title}</p>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })}
          </Carousel>
        )}
      </div>
    );
  }
  /** Convert Content to HTML */
  MyComponent(desc) {
    return <div dangerouslySetInnerHTML={this.createMarkup(desc)} />;
  }
  createMarkup(desc) {
    return { __html: desc };
  }
  /** Convert Content to HTML End*/
}

export default CarouselWhyChooseUs;
