import React, { Component } from "react";

import Global from "../Global.json";

import Coverflow from "react-coverflow";
import Slider from "react-slick";


var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true
};

class CarouselCoverFlow extends Component {
    constructor(props) {
        super(props);
        this.state = {};

    }
    render() {
        return (
            <div className="carousel-cover-flow carousel-cover-flow-New">
                {/*  add section  */}

                <Slider {...settings}>
                    {this.props.ads.map(slide => {
                        return (
                            <>
                                <div>
                                    <a href={slide.link}>
                                        <img src={Global.image_link + slide.image} alt="" />
                                    </a>

                                </div>
                            </>
                        )
                    })}

                </Slider>

                {/* <Coverflow width={960} height="300" className="cover-flow" displayQuantityOfSide={1.5} enableScroll={false} clickable={true} active={0} navigation={false} infiniteScroll={true} enableHeading={false} currentFigureScale={1.9} otherFigureScale={1.2}>
                    <div 
                        role="menuitem"
                        tabIndex="0"
                    >
                         
                    </div>
                 

                    {this.props.ads.map((item, index) => {
                        return <img src={Global.image_link + item.image} alt="l" data-action={item.link} key={index} height="100px" />;
                    })} 
                </Coverflow> */}
                {/*  end add section  */}
                <img src="/img/Ellipse52cut.png" alt="l" className="half-circle" />
            </div>
        );
    }
}
export default CarouselCoverFlow;
