import React, { Component } from "react";
import ScrollAnimation from "react-animate-on-scroll";

import { Container, Col, Row, Carousel } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/css/modal-video.min.css";
import ConnectUs from "../blocks/ConnectUs";
import Global from "../Global.json";

class WorkExperience extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            scrollDown: true,
            about: {},
            aboutLoader: true,
            consultations: "",
            training: "",
            courses: "",
            books: "",
            countersLoader: true,
            partners: [],
            partnersLoader: true,
        };
    }

    componentDidMount() {
        this.get_about();
        this.get_about_counters();
        this.get_partners();

        // to hide  scroll down button

        window.addEventListener("scroll", () => {
            this.setState({
                scrollDown: false,
            });
        });
        // to hide  scroll down button
    }

    /** Get about Start  */

    get_about() {
        fetch(Global.api_link + "get_about", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    about: result.data,
                    aboutLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get about End */

    /** Get counters Start  */

    get_about_counters() {
        fetch(Global.api_link + "get_about_counters", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    consultations: result.consultations,
                    training: result.training,
                    courses: result.courses,
                    books: result.books,
                    countersLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get counters End */

    /** Get partners Start  */

    get_partners() {
        fetch(Global.api_link + "get_partners", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                service_id: 3,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    partners: result.data,
                    partnersLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get partners End */
    render() {
        return (
            <div className="WorkExperience">
                {/* landing page */}

                <div className="queries">
                    <Container fluid>
                        <Row className="justify-content-space-between">
                            {/*  landing page discriptions types */}

                            <Col lg={7} className="z-index-top">
                                <Row>
                                    <Col xl={7} lg={8} className="workExperience-discription-col">
                                        <div className="workExperience-discription">
                                            <ScrollAnimation animateIn="bounceInRight" animateOnce={true} duration={2}>
                                                {this.state.aboutLoader ? <Skeleton height={50} width={200} /> : <h4>{this.state.about.name}</h4>}
                                            </ScrollAnimation>
                                            {this.state.aboutLoader ? <Skeleton height={300} /> : this.MyComponent(this.state.about.short_description)}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            {/* end  landing page discriptions types */}

                            {/*  landing page model video */}

                            <Col lg={4} sm={12} className="workExperience-modal-video">
                                {this.state.aboutLoader ? (
                                    <Skeleton height={400} />
                                ) : (this.state.about.video === null || this.state.about.video == "") && (this.state.about.video_link === null || this.state.about.video_link === "") ? null : (
                                    <>
                                        <ScrollAnimation animateIn="zoomIn" animateOnce={true} className="queries-video" duration={2}>
                                            {/* <div className="queries-video"> */}
                                            <img src={Global.image_link + this.state.about.video_image} alt="l" />
                                            {/* <img onClick={() => this.setState({ isOpen: true })} src="/img/Group832.png" alt="l" /> */}
                                            {/* </div> */}
                                        </ScrollAnimation>
                                        <ModalVideo
                                            channel="custom"
                                            autoplay
                                            isOpen={this.state.isOpen}
                                            url={this.state.about.video === null || this.state.about.video == "" ? this.state.about.video_link : Global.image_link + this.state.about.video}
                                            onClose={() => {
                                                this.setState({ isOpen: false });
                                            }}
                                        />
                                    </>
                                )}
                            </Col>
                            {/* end  landing page model video */}
                        </Row>
                    </Container>
                    {/* points img  */}

                    <div className="queries-points-img">
                        <img src="/img/Group783.png" alt="l" />
                    </div>
                    {/* end points img  */}

                    {/* circle  img  */}

                    <div className="queries-circle">
                        <img src="/img/Group831cut.png" alt="l" />
                    </div>
                    {/* end circle  img  */}
                </div>
                {/* end landing page */}

                {/* more details section  */}
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="more-details">{this.state.aboutLoader ? <Skeleton height={300} /> : this.MyComponent(this.state.about.long_description)}</div>
                        </Col>
                    </Row>
                </Container>

                {/* end more details section  */}

                {/* The number of trainees */}
                <div className="numOfTrainees">
                    <Container fluid>
                        <Row className="justify-content-center">
                            <Col lg={3} sm={6}>
                                <ScrollAnimation animateIn="zoomIn" animateOnce={true} duration={1}>
                                    <div className="numOfTrainees-item">
                                        <img src="/img/support(1).png" alt="l" />
                                        <p>إستشارة</p>
                                        <span>{this.state.countersLoader ? <Skeleton height={40} width={50} style={{ margin: "auto" }} /> : this.state.consultations}</span>
                                    </div>
                                </ScrollAnimation>
                            </Col>
                            <Col lg={3} sm={6}>
                                <ScrollAnimation animateIn="zoomIn" animateOnce={true} duration={2}>
                                    <div className="numOfTrainees-item">
                                        <img src="/img/training(2).png" alt="l" />
                                        <p>تدريب</p>
                                        <span>{this.state.countersLoader ? <Skeleton height={40} width={50} style={{ margin: "auto" }} /> : this.state.training}</span>
                                    </div>
                                </ScrollAnimation>
                            </Col>
                            <Col lg={3} sm={6}>
                                <ScrollAnimation animateIn="zoomIn" animateOnce={true} duration={3}>
                                    <div className="numOfTrainees-item">
                                        <img src="/img/consulting.png" alt="l" />
                                        <p>دورة</p>
                                        <span>{this.state.countersLoader ? <Skeleton height={40} width={50} style={{ margin: "auto" }} /> : this.state.courses}</span>
                                    </div>
                                </ScrollAnimation>
                            </Col>
                            <Col lg={3} sm={6}>
                                <ScrollAnimation animateIn="zoomIn" animateOnce={true} duration={4}>
                                    <div className="numOfTrainees-item">
                                        <img src="/img/article.png" alt="l" />
                                        <p>مقالة</p>
                                        <span>{this.state.countersLoader ? <Skeleton height={40} width={50} style={{ margin: "auto" }} /> : this.state.books}</span>
                                    </div>
                                </ScrollAnimation>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* end The number of trainees */}

                {/* The goodness of work section  */}
                <div className="goodnessOfWork">
                    <Container fluid>
                        <Row>
                            {/*  goodness of work title  */}
                            <Col xs={12}>
                                <ScrollAnimation animateIn="rotateInDownRight" animateOnce={true} duration={1}>
                                    <div className="ourServices-title" id="goodnessOfWork">
                                        <h3>خبرات العمل</h3>
                                    </div>
                                </ScrollAnimation>
                            </Col>
                            {/* end  goodness of work title  */}

                            <Col xs={12}>
                                <div className="goodnessOfWork-discription ">{this.state.aboutLoader ? <Skeleton height={300} /> : this.MyComponent(this.state.about.work_experience)}</div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                {/* end The goodness of work section  */}

                {/* Our clients section  */}
                <div className="ourClients-workExperience" id="ourClients">
                    <Container fluid>
                        <Row className="justify-content-space-between">
                            <Col xs={12}>
                                {/* Our clients carousel  */}

                                <Carousel controls={false} interval={3000}>
                                    {this.state.partnersLoader ? (
                                        <Row>
                                            <Col xs={3}>
                                                <Skeleton height={100} width={150} />
                                            </Col>
                                            <Col xs={3}>
                                                <Skeleton height={100} width={150} />
                                            </Col>
                                            <Col xs={3}>
                                                <Skeleton height={100} width={150} />
                                            </Col>
                                            <Col xs={3}>
                                                <Skeleton height={100} width={150} />
                                            </Col>
                                        </Row>
                                    ) : (
                                        this.state.partners.map((item, index) => {
                                            return (
                                                <Carousel.Item>
                                                    <div className="ourClients-carousle-item" key={index}>
                                                        {item.map((img, Imgindex) => {
                                                            return <img src={Global.image_link + img.image} alt={img.title} key={Imgindex} />;
                                                        })}
                                                    </div>
                                                </Carousel.Item>
                                            );
                                        })
                                    )}
                                </Carousel>
                                {/* end Our clients carousel  */}
                            </Col>
                        </Row>
                    </Container>
                </div>

                {/* end  Our clients section  */}
            </div>
        );
    }

    /** Convert Content to HTML */
    MyComponent(desc) {
        return <div dangerouslySetInnerHTML={this.createMarkup(desc)} />;
    }
    createMarkup(desc) {
        return { __html: desc };
    }
    /** Convert Content to HTML End*/
}
export default WorkExperience;
