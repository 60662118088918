import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/css/modal-video.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CarouselCoverFlow from "../blocks/CarouselCoverFlow";
import LinesEllipsis from "react-lines-ellipsis";
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import "react-image-gallery/styles/css/image-gallery.css";
import Training from "../blocks/Training";
import Global from "../Global.json";
import Skeleton from "react-loading-skeleton";

import ScrollAnimation from "react-animate-on-scroll";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollDown: true,
            images: [],
            newSlide: [],
            newImages: [],
            briefLoader: true,
            mainName: "",
            mainImage: "",
            briefParagraph: "",
            briefTitle: "",
            AdsData: [],
            AdsLoader: true,
            serviceDescription_text: {},
            services: [],
            MainServiceLoader: true,
            trainingDescription_text: {},
            courses: [],
            randomTrainingLoader: true,
            testimonialsDescription: {},
            testimonialsLoader: true,
            consultationName: "",
            consultationDescription: "",
            consultationvideo: "",
            consultationvideo_link: "",
            consultationVideo_image: "",
            consultationLoader: true,
            consultations_specifications: [],
            consultations_specificationsLoader: true,
            books: [],
            booksLoader: true,
            bookIndex: 0,
            goToSlide: 0,
            goToSlide1: 0,
            serviceDescription_title: ""
        };
    }

    // render main img gallery carousel
    _renderCustomControls(item) {
        return (
            <div className="recommendations-card">
                {/* {this.state.testimonialsLoader ? (
                    <Skeleton height={250} />
                ) : ( */}
                <>
                    <div className="Employee-info-bg"> </div>
                    <div className="Employee-info">
                        <h4>item.name</h4>
                        <h6>"item.position"</h6>
                        <img src="/img/“.png" alt="icon" />

                        <p>
                            {/* {item.text} */}
                            {/* {this.MyComponent(item.text)} */}
                        </p>
                    </div>
                </>
                {/* )} */}
            </div>
        );
    }
    // end render main img gallery carousel

    // render thubs img gallery carousel

    _renderCustomThumbs(item) {
        return (
            <ScrollAnimation animateIn="bounceIn" duration={3} animateOnce={true}>
                {this.state.testimonialsLoader ? <Skeleton height={90} /> : <img className="thumb-employee-img " src={Global.image_link + item.image} alt="l" />}
            </ScrollAnimation>
        );
    }
    // end render thubs img gallery carousel

    componentDidMount() {
        this.brief();
        this.get_advertise();
        this.get_main_services();
        this.get_random_training();
        this.get_testimonials();
        this.get_service_info();
        this.consultations_specifications();
        this.get_books();

        ///// to hide  scroll down button

        window.addEventListener("scroll", () => {
            this.setState({
                scrollDown: false,
            });
        });
        ///// to hide  scroll down button
    }

    /** Get brief Start  */

    brief() {
        fetch(Global.api_link + "brief", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    mainName: result.data.name,
                    mainImage: result.data.image,
                    briefParagraph: result.data.brief,
                    briefTitle: result.data.position,
                    briefLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get brief End */

    /** Get ads Start  */

    get_advertise() {
        fetch(Global.api_link + "get_advertise", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    AdsData: result.data,
                    AdsLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get ads End */

    /** Get main services Start  */

    get_main_services() {
        fetch(Global.api_link + "get_main_services", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    serviceDescription_text: this.MyComponent(result.description_text.description),
                    serviceDescription_title: result.description_text.title,
                    services: result.services,
                    MainServiceLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get get_main_services End */

    /** Get random_training Start  */

    get_random_training() {
        fetch(Global.api_link + "get_random_training", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    trainingDescription_text: result.description_text,
                    courses: result.courses,
                    randomTrainingLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get random_training End */

    /** Get testimonials Start  */

    get_testimonials() {
        fetch(Global.api_link + "get_testimonials", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                let arr = [];

                if (result.testimonials) {
                    result.testimonials.map((item, index) => {
                        arr.push({
                            key: index,
                            content: (
                                <div className="recommendations-card">
                                    <>
                                        {/* <div className="Employee-info-bg"> </div> */}
                                        <img className="thumb-employee-img " src={Global.image_link + item.image} alt="l" />
                                        <div className="Employee-info">
                                            <h4>{item.name}</h4>
                                            <h6>{item.position}</h6>
                                            <img src="/img/“.png" alt="icon" />

                                            <p>
                                                {/* {item.text} */}
                                                {this.MyComponent(item.text)}
                                            </p>
                                        </div>
                                    </>
                                    {/* )} */}
                                </div>
                            ),
                        });
                    });
                }
                this.setState({
                    testimonialsDescription: result.description,
                    images: result.testimonials,
                    testimonialsLoader: false,
                    newImages: arr.map((item, index) => {
                        return {
                            ...item,
                            onClick: () => this.setState({ goToSlide1: index }),
                        };
                    }),
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get testimonials End */

    /** Get service_info Start  */

    get_service_info() {
        fetch(Global.api_link + "get_service_info", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                service_id: 1,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    consultationName: result.data.name,
                    consultationDescription: result.data.description,
                    consultationvideo: result.data.video,
                    consultationvideo_link: result.data.video_link,
                    consultationVideo_image: result.data.video_image,
                    consultationLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get service_info End */

    /** Get consultations_specifications Start  */

    consultations_specifications() {
        fetch(Global.api_link + "consultations_specifications", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                limit: 3,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    consultations_specifications: result.data,
                    consultations_specificationsLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get consultations_specifications End */

    /** Get books Start  */

    get_books() {
        fetch(Global.api_link + "get_books", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                let arr = [];

                if (result.data) {
                    result.data.map((slide, index) => {
                        arr.push({
                            key: index,
                            content: (
                                <img
                                    src={Global.image_link + slide.image}
                                    alt="1"
                                    onClick={() => {
                                        window.location.href = "/BookPage/" + index;
                                    }}
                                />
                            ),
                        });
                    });
                }
                this.setState({
                    books: result.data,
                    booksLoader: false,
                    newSlide: arr.map((slide, index) => {
                        return {
                            ...slide,
                            onClick: () => this.setState({ goToSlide: index }),
                        };
                    }),
                    // newSlide:arr
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get books End */
    render() {
        return (
            <div className="Home">
                {/*   Landing section  */}

                <div className="landing">
                    <Container fluid>
                        {this.state.briefLoader ? (
                            <Skeleton height={700} />
                        ) : (
                            <Row className="landing-row">
                                {/* landing-details */}
                                <Col md={6} sm={12} xs={12}>
                                    <div className="landing-details">
                                        <h6>{this.state.briefTitle}</h6>

                                        {/* asfor name */}

                                        <h2 className="line-1 anim-typewriter"> {this.state.mainName}</h2>

                                        {/* end asfor name */}

                                        <ScrollAnimation animateIn="bounceInRight" animateOnce={true} offset={0} duration={3}>
                                            {/* landing pharagraph */}

                                            {this.MyComponent(this.state.briefParagraph)}

                                            {/* end landing pharagraph */}
                                        </ScrollAnimation>
                                        {/* landing button  */}
                                        <button
                                            className="know-more-button"
                                            onClick={() => {
                                                window.location.href = "/WorkExperience";
                                            }}
                                        >
                                            اكتشف اكثر
                                        </button>
                                        {/* end landing button  */}
                                    </div>
                                    {/* )} */}
                                </Col>
                                {/* end landing-details */}

                                {/* person-bg */}
                                <Col md={6} sm={12} xs={12} className="person-bg-col">
                                    {this.state.briefLoader ? (
                                        <Skeleton height={500} />
                                    ) : (
                                        <ScrollAnimation animateIn="fadeIn" animateOnce={true} style={{ width: "100%" }} offset={0} duration={3}>
                                            <img src={Global.image_link + this.state.mainImage} alt="l" />
                                        </ScrollAnimation>
                                    )}
                                </Col>
                                {/* end person-bg */}
                            </Row>
                        )}
                    </Container>
                </div>

                {/*  end  Landing section  */}

                {/* Carousel Cover Flow */}
                {this.state.AdsLoader ? <Skeleton height={200} /> : <CarouselCoverFlow ads={this.state.AdsData} />}
                {/* end Carousel Cover Flow */}

                {/*  OurServices section  */}
                <div className="OurServices">
                    <Container fluid>
                        <Row>
                            {/*  OurServices  title  */}

                            <Col xs={12}>
                                <div className="ourServices-title">
                                    <ScrollAnimation animateIn="rotateInDownRight" animateOnce={true} duration={1}>
                                        {this.state.MainServiceLoader ? <Skeleton height={40} width={200} /> : <h3>{this.state.serviceDescription_title}</h3>}
                                    </ScrollAnimation>
                                </div>
                            </Col>
                            {/* end OurServices  title  */}

                            {/*  OurServices  description  */}

                            <Col xs={12}>
                                {this.state.MainServiceLoader ? (
                                    <Skeleton height={20} width={500} />
                                ) : (
                                    <div className="ourServices-paragraph">
                                        <p>{this.state.serviceDescription_text}</p>
                                    </div>
                                )}
                            </Col>
                            {/* end OurServices  description  */}

                            {/*  OurServices  tabs  */}
                            {this.state.MainServiceLoader ? (
                                <>
                                    <Col sm={4}>
                                        <Skeleton height={200} />
                                    </Col>
                                    <Col sm={4}>
                                        <Skeleton height={200} />
                                    </Col>
                                    <Col sm={4}>
                                        <Skeleton height={200} />
                                    </Col>
                                </>
                            ) : (
                                this.state.services.map((item, index) => {
                                    const myText = this.MyComponent(item.description); // if description came like HTML tag use this text
                                    return (
                                        <Col sm={4} key={index}>
                                            <div className="ourService-tab-section">
                                                <div className="ourService-hover">
                                                    <div className={this.state.makeHover ? "tab-icon hover-tab-icon" : "tab-icon"}>
                                                        <ScrollAnimation animateIn="swing" animateOnce={true} duration={3}>
                                                            <img src={Global.image_link + item.icon} alt="ll" />
                                                        </ScrollAnimation>
                                                    </div>
                                                    <div className="routService">
                                                        <h4>{item.name}</h4>
                                                        <p className="TextDescription overflow-clip-ellipsis "><LinesEllipsis text={myText} maxLine={3} ellipsis="..." trimRight basedOn="letters" /></p>

                                                    </div>
                                                </div>
                                                <button
                                                    onClick={() => {
                                                        window.location.href = item.id == 1 ? "/Packages" : item.id == 2 ? " /training" : "/WhyChooseUs";
                                                    }}
                                                >
                                                    اقرأ المزيد
                                                </button>
                                            </div>
                                        </Col>
                                    );
                                })
                            )}
                            {/* end OurServices  tabs  */}
                        </Row>
                    </Container>
                </div>
                {/*  end OurServices section  */}

                {/*  Training section  */}

                {this.state.randomTrainingLoader ? <Skeleton height={500} /> : <Training withIcon={false} courses={this.state.courses} trainingDescription={this.state.trainingDescription_text} randomTrainingLoader={this.state.randomTrainingLoader} />}
                {/* end Training section  */}

                {/*  queries  section  */}
                {this.state.consultationLoader && this.state.consultationName && (
                    <div className="queries" id="queries">
                        <Container fluid>
                            <Row>
                                {this.state.consultationLoader ? (
                                    <Col xs={12}>
                                        <Skeleton height={50} />
                                    </Col>
                                ) : (
                                    <>
                                        {/* Queries title  */}
                                        <Col xs={12}>
                                            <div className="ourServices-title">
                                                <ScrollAnimation animateIn="rotateInDownRight" animateOnce={true} duration={2}>
                                                    <h3>{this.state.consultationName}</h3>
                                                </ScrollAnimation>
                                            </div>
                                        </Col>
                                        {/* end Queries title  */}

                                        {/* Queries description */}

                                        <Col xs={12}>
                                            <div className="ourServices-paragraph">
                                                <p>{this.MyComponent(this.state.consultationDescription)}</p>
                                            </div>
                                        </Col>
                                        {/* end Queries description */}
                                    </>
                                )}

                                {/* Queries types */}

                                <Col lg={8} className="z-index-top">
                                    <Row>
                                        {this.state.consultations_specificationsLoader ? (
                                            <>
                                                <Col sm={4}>
                                                    <Skeleton height={300} />
                                                </Col>
                                                <Col sm={4}>
                                                    <Skeleton height={300} />
                                                </Col>
                                                <Col sm={4}>
                                                    <Skeleton height={300} />
                                                </Col>
                                            </>
                                        ) : (
                                            this.state.consultations_specifications.map((item, index) => {
                                                return (
                                                    <Col sm={4} key={index}>
                                                        <ScrollAnimation animateIn="zoomIn" animateOnce={true} className="queries-info" duration={1}>
                                                            <img src={Global.image_link + item.icon} alt="l" />

                                                            <h3>{item.name}</h3>

                                                            <span>{this.MyComponent(item.text)}</span>
                                                        </ScrollAnimation>
                                                    </Col>
                                                );
                                            })
                                        )}

                                        {/* queries know-more-button */}
                                        <Col sm={12} className="queries-col-know-more-buttom">
                                            <button
                                                className="know-more-button"
                                                onClick={() => {
                                                    window.location.href = "/Packages";
                                                }}
                                            >
                                                اكتشف اكثر
                                            </button>
                                        </Col>
                                        {/* end queries know-more-button */}
                                    </Row>
                                </Col>
                                {/* end Queries types */}

                                {/* Queries model video */}

                                <Col lg={4} sm={12}>
                                    {this.state.consultationLoader ? (
                                        <Skeleton height={400} />
                                    ) : (this.state.consultationvideo === null || this.state.consultationvideo == "") && (this.state.consultationvideo_link === null || this.state.consultationvideo_link === "") ? null : (
                                        <>
                                            <ScrollAnimation animateIn="zoomIn" animateOnce={true} className="queries-video" duration={2}>
                                                <img src={Global.image_link + this.state.consultationVideo_image} alt="l" />
                                                <img onClick={() => this.setState({ isOpen: true })} src="/img/Group832.png" alt="l" />
                                            </ScrollAnimation>

                                            <ModalVideo
                                                channel="custom"
                                                autoplay
                                                isOpen={this.state.isOpen}
                                                url={this.state.consultationvideo === null || this.state.consultationvideo == "" ? this.state.consultationvideo_link : Global.image_link + this.state.consultationvideo}
                                                onClose={() => {
                                                    this.setState({ isOpen: false });
                                                }}
                                            />
                                        </>
                                    )}
                                </Col>
                                {/* end Queries model video */}
                            </Row>
                        </Container>

                        {/* points img  */}
                        <div className="queries-points-img">
                            <img src="/img/Group783.png" alt="l" />
                        </div>
                        {/* end points img  */}

                        {/* queries-circle  img  */}
                        <div className="queries-circle">
                            <img src="/img/Group831cut.png" alt="l" />
                        </div>
                        {/* end queries-circle  img  */}
                    </div>
                )}
                {/* end queries  section  */}
                {/* Carousel Cover Flow */}
                {/* {this.state.AdsLoader ? <Skeleton height={200} /> : <CarouselCoverFlow ads={this.state.AdsData} />} */}
                {/* end Carousel Cover Flow */}

                {/*  book section  */}
                <div className="books">
                    <Container fluid>
                        <Row>
                            {/* books-carousel */}
                            <Col lg={5}>
                                <ScrollAnimation animateIn="bounce" animateOnce={true} offset={0} duration={2}>
                                    <div className="books-carousel">
                                        {this.state.booksLoader ? (
                                            <Skeleton height={300} />
                                        ) : (
                                            <>
                                                <img
                                                    src="/img/Icon feather-arrow-down-left.png"
                                                    alt="next"
                                                    className="books-arrow-left"
                                                    onClick={() => {
                                                        if (this.state.goToSlide <= 0) {
                                                            this.setState({
                                                                goToSlide: this.state.books.length - 1,
                                                            });
                                                        } else {
                                                            this.setState({
                                                                goToSlide: this.state.goToSlide - 1,
                                                            });
                                                        }
                                                    }}
                                                />
                                                <Carousel slides={this.state.newSlide} goToSlide={this.state.goToSlide} offsetRadius={2} showNavigation={true} animationConfig={config.gentle} />
                                                <img
                                                    src="/img/Iconfeather-arrow-down.png"
                                                    alt="next"
                                                    className="books-arrow-right"
                                                    onClick={() => {
                                                        if (this.state.goToSlide >= this.state.books.length - 1) {
                                                            this.setState({
                                                                goToSlide: 0,
                                                            });
                                                        } else {
                                                            this.setState({
                                                                goToSlide: this.state.goToSlide + 1,
                                                            });
                                                        }
                                                    }}
                                                />
                                            </>
                                        )}
                                    </div>
                                </ScrollAnimation>
                            </Col>
                            {/* end books-carousel */}

                            <Col lg={7}>
                                {/* book name  */}
                                <Col xs={12}>
                                    {this.state.booksLoader ? null : (
                                        <div className="ourServices-title">
                                            <h3
                                                onClick={() => {
                                                    window.location.href = "/BookPage/" + this.state.goToSlide;
                                                }}
                                            >
                                                {this.state.books[this.state.goToSlide].name}
                                            </h3>
                                        </div>
                                    )}
                                </Col>
                                {/* end book name  */}

                                {/* book detailes */}

                                <Col xs={12}>
                                    {this.state.booksLoader ? (
                                        <Skeleton height={350} />
                                    ) : (
                                        <div className="ourServices-paragraph">
                                            <span>{this.MyComponent(this.state.books[this.state.goToSlide].descr)}</span>
                                        </div>
                                    )}
                                </Col>
                                {/* book detailes */}
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/*  end  book section  */}

                {/* recommendations section */}
                {this.state.testimonialsLoader.length >0 ? <>
                <div className="recommendations">
                    <Container fluid>
                        <Row>
                            {/* recommendations title */}
                            <Col xs={12}>
                                <div className="ourServices-title text-right">
                                    <ScrollAnimation animateIn="rotateInDownRight" animateOnce={true} duration={1}>
                                        {this.state.testimonialsLoader ? <Skeleton height={50} width={150} /> : <h3>{this.state.testimonialsDescription.title}</h3>}
                                    </ScrollAnimation>
                                </div>
                            </Col>
                            {/* recommendations title */}
                            {/* recommendations description */}

                            <Col xs={12}>
                                <div className="ourServices-paragraph text-right">{this.state.testimonialsLoader ? <Skeleton height={100} /> : <p>{this.MyComponent(this.state.testimonialsDescription.description)}</p>}</div>
                            </Col>
                            {/* end recommendations description */}

                            {/* recommendations ImageGallery */}
                            <Col sm={12}>
                                <div style={{ height: "500px" }}>
                                    <img
                                        src="/img/Icon feather-arrow-down-left.png"
                                        alt="next"
                                        className="testimonials-arrow-left"
                                        onClick={() => {
                                            if (this.state.goToSlide1 <= 0) {
                                                this.setState({
                                                    goToSlide1: this.state.images.length - 1,
                                                });
                                            } else {
                                                this.setState({
                                                    goToSlide1: this.state.goToSlide1 - 1,
                                                });
                                            }
                                        }}
                                    />
                                    <Carousel showNavigation={false} height="500px" slides={this.state.newImages} goToSlide={this.state.goToSlide1} offsetRadius={1} animationConfig={config.gentle} />
                                    <img
                                        src="/img/Iconfeather-arrow-down.png"
                                        alt="next"
                                        className=" testimonials-arrow-right"
                                        onClick={() => {
                                            if (this.state.goToSlide1 >= this.state.images.length - 1) {
                                                this.setState({
                                                    goToSlide1: 0,
                                                });
                                            } else {
                                                this.setState({
                                                    goToSlide1: this.state.goToSlide1 + 1,
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            </Col>
                            {/* end recommendations ImageGallery */}
                        </Row>
                    </Container>
                    {/* queries-circle img  */}
                    <div className="queries-circle">
                        <img src="/img/Ellipse90cut.png" alt="l" />
                    </div>
                    {/* end queries-circle img  */}

                    {/* points img  */}
                    <img src="/img/asfour1.png" alt="l" className="header-point-img" />
                    {/* end points img  */}
                </div>
                </>  :null}
                {/*  end recommendations section */}
            </div>
        );
    }

    /** Convert Content to HTML */
    MyComponent(desc) {
        return <div dangerouslySetInnerHTML={this.createMarkup(desc)} />;
    }
    createMarkup(desc) {
        return { __html: desc };
    }
    /** Convert Content to HTML End*/
}

export default Home;
