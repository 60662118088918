import React, { Component } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Row, Carousel } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import ConnectUs from "../blocks/ConnectUs";
import Global from "../Global.json";

class Article extends Component {
    constructor(props) {
        super(props);
        this.state = {
            articleData: [],
            MostLikedArticle: [],
            RandomArticle: [],
            articleDataLoader: true,
        };
    }

    componentWillMount() {
        this.get_blogs();
    }

    /** Get blogs Start  */

    get_blogs() {
        fetch(Global.api_link + "get_blogs", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                limit: 3,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    articleData: result.data,
                    articleDataLoader: false,
                    MostLikedArticle: result.MostLiked,
                    RandomArticle: result.Random,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get blogs End */

    render() {
        return (
            <div className="Article">
                <Container fluid>
                    {this.state.articleDataLoader ? (
                        <Skeleton height={400} />
                    ) : (
                        <Carousel interval={null}>
                            {this.state.articleData.map((item, index) => {
                                return (
                                    <Carousel.Item>
                                        <Row>
                                            <Col sm={6}>
                                                {/* article name  */}
                                                <Col xs={12}>
                                                    <a href={"/ArticleDetails/" + item.id}>
                                                        <h2 className="article-title">{item.title}</h2>
                                                    </a>
                                                </Col>
                                                {/* end article name  */}

                                                {/* article detailes */}

                                                <Col xs={12}>
                                                    <div className="article-paragraph">
                                                        <span>{this.MyComponent(item.descr)}</span>
                                                    </div>
                                                    {/* time to read and date  */}
                                                    <div className="timeToRead">
                                                        <span>{item.date}</span>
                                                        <span>تحتاج {item.time_to_read} للقراءة</span>
                                                    </div>
                                                    {/* time to read and date end */}
                                                </Col>
                                                {/* article detailes end */}
                                            </Col>
                                            {/* article img  */}

                                            <Col sm={6} className="margin-auto">
                                                {/* <ScrollAnimation
                          animateIn="zoomIn"
                          animateOnce={true}
                          className="article-img"
                          duration={2}
                        > */}
                                                <div className="article-img">
                                                    <img
                                                        onClick={() => (window.location.href = "/ArticleDetails/" + item.id)}
                                                        src={Global.image_link + item.image}
                                                        // src="/img/c1754209c390f82db32c1015ddff6280.png"
                                                        alt="First slide"
                                                    />
                                                    <div className="numImgLikeCourasel">
                                                        <div
                                                            onClick={() => {
                                                                this.addLike(item.id);
                                                            }}
                                                        >
                                                            <img src="/img/like(2).png" alt="l" />
                                                            <span>{this.kFormatter(item.likes)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* </ScrollAnimation> */}
                                            </Col>
                                            {/* article img end */}
                                        </Row>
                                    </Carousel.Item>
                                );
                            })}
                        </Carousel>
                    )}

                    {/* circle  img  */}
                    <div className="article-circle">
                        <img src="/img/Group872.png" alt="l" />
                    </div>
                    {/* end circle  img  */}
                </Container>

                {/* random article sedtion 1 */}
                <div className="random-article">
                    <Container fluid>
                        <Row>
                            {this.state.articleDataLoader ? (
                                <>
                                    <Col lg={5}>
                                        <Skeleton height={400} />
                                    </Col>
                                    <Col lg={7}>
                                        <Col lg={12}>
                                            <Skeleton height={200} />
                                        </Col>
                                        <Col lg={12}>
                                            <Skeleton height={200} />
                                        </Col>
                                    </Col>
                                </>
                            ) : (
                                <>
                                    {/* random artical left side  */}
                                    <Col lg={5}>
                                        {/* <div className="random-article-left"> */}
                                        <ScrollAnimation animateIn="zoomIn" animateOnce={true} className="random-article-left" duration={2}>
                                            {/* main img  */}
                                            <a href={"/ArticleDetails/" + this.state.MostLikedArticle[0].id}>
                                                <img className="mainArticalImg" src={Global.image_link + this.state.MostLikedArticle[0].image} alt="l" />
                                            </a>
                                            {/* main img  */}

                                            {/* article name  */}
                                            <a href={"/ArticleDetails/" + this.state.MostLikedArticle[0].id}>
                                                <h2>{this.state.MostLikedArticle[0].title}</h2>
                                            </a>
                                            {/* end article name  */}
                                            {/* article detailes */}
                                            <div className="article-paragraph">
                                                <span>{this.MyComponent(this.state.MostLikedArticle[0].descr)}</span>
                                            </div>

                                            {/* time to read and date  */}
                                            <div className="likeAndTime">
                                                <div className="timeToRead-ramdom-article">
                                                    <span>{this.state.MostLikedArticle[0].date}</span>
                                                    <span>تحتاج {this.state.MostLikedArticle[0].time_to_read} للقراءة </span>
                                                </div>

                                                <div
                                                    className="numImgLike"
                                                    onClick={() => {
                                                        this.addLike(this.state.MostLikedArticle[0].id);
                                                    }}
                                                >
                                                    <img src="/img/like(2).png" alt="l" />
                                                    <span>{this.kFormatter(this.state.MostLikedArticle[0].likes)}</span>
                                                </div>
                                            </div>
                                            {/* time to read and date end */}
                                            {/* article detailes */}

                                            {/* </div> */}
                                        </ScrollAnimation>
                                    </Col>
                                    {/* random artical left side  end */}

                                    {/* random artical right side  */}

                                    <Col lg={7}>
                                        {/* <div className="random-article-right"> */}
                                        <ScrollAnimation animateIn="zoomIn" animateOnce={true} className="random-article-right " duration={2}>
                                            {/* article img */}

                                            <img onClick={() => (window.location.href = "/ArticleDetails/" + this.state.MostLikedArticle[1]?.id)} className="mainArticalImg" src={Global.image_link + this.state.MostLikedArticle[1]?.image} alt="l" />
                                            {/* article img end */}

                                            <div className="random-article-detalis">
                                                {/* article name  */}
                                                <a href={"/ArticleDetails/" + this.state.MostLikedArticle[1]?.id}>
                                                    <h2>{this.state.MostLikedArticle[1]?.title}</h2>
                                                </a>
                                                {/* end article name  */}

                                                {/* article detailes */}
                                                <div className="article-paragraph">
                                                    <span>{this.MyComponent(this.state.MostLikedArticle[1]?.descr)}</span>
                                                </div>

                                                {/* time to read and date  */}
                                                <div className="likeAndTime">
                                                    <div className="timeToRead-ramdom-article">
                                                        <span>{this.state.MostLikedArticle[1]?.date}</span>
                                                        <span>تحتاج {this.state.MostLikedArticle[1]?.time_to_read} للقراءة </span>
                                                    </div>
                                                    <div
                                                        className="numImgLike"
                                                        onClick={() => {
                                                            this.addLike(this.state.MostLikedArticle[1]?.id);
                                                        }}
                                                    >
                                                        <img src="/img/like(2).png" alt="l" />
                                                        <span>{this.kFormatter(this.state.MostLikedArticle[1]?.likes)}</span>
                                                    </div>
                                                </div>

                                                {/* time to read and date end */}
                                                {/* article detailes emd  */}
                                            </div>
                                            {/* </div> */}
                                        </ScrollAnimation>

                                        {/* <div className="random-article-right"> */}
                                        <ScrollAnimation animateIn="zoomIn" animateOnce={true} className="random-article-right " duration={2}>
                                            {/* article img  */}

                                            <img onClick={() => (window.location.href = "/ArticleDetails/" + this.state.MostLikedArticle[2]?.id)} className="mainArticalImg" src={Global.image_link + this.state.MostLikedArticle[2]?.image} alt="l" />
                                            {/* article img end */}

                                            <div className="random-article-detalis">
                                                {/* article name  */}
                                                <a href={"/ArticleDetails/" + this.state.MostLikedArticle[2]?.id}>
                                                    <h2>{this.state.MostLikedArticle[2]?.title}</h2>
                                                </a>
                                                {/* end article name  */}
                                                {/* article detailes */}
                                                <div className="article-paragraph">
                                                    <span>{this.MyComponent(this.state.MostLikedArticle[2]?.descr)}</span>
                                                </div>

                                                {/* time to read and date  */}
                                                <div className="likeAndTime">
                                                    <div className="timeToRead-ramdom-article">
                                                        <span>{this.state.MostLikedArticle[2]?.date}</span>
                                                        <span>تحتاج {this.state.MostLikedArticle[2]?.time_to_read} للقراءة </span>
                                                    </div>
                                                    <div
                                                        className="numImgLike"
                                                        onClick={() => {
                                                            this.addLike(this.state.MostLikedArticle[2]?.id);
                                                        }}
                                                    >
                                                        <img src="/img/like(2).png" alt="l" />
                                                        <span>{this.kFormatter(this.state.MostLikedArticle[2]?.likes)}</span>
                                                    </div>{" "}
                                                </div>
                                                {/* time to read and date end */}
                                                {/* article detailes end */}
                                                {/* </div> */}
                                            </div>
                                        </ScrollAnimation>
                                    </Col>
                                    {/* random artical right side  */}
                                </>
                            )}
                        </Row>
                    </Container>
                </div>
                {/* random article sedtion 1 end */}

                {/* random article sedtion 2 */}
                <div className="random-article random-article-sec2">
                    <Container fluid>
                        <Row>
                            {this.state.articleDataLoader ? (
                                <>
                                    <Col lg={7}>
                                        <Col lg={12}>
                                            <Skeleton height={200} />
                                        </Col>
                                        <Col lg={12}>
                                            <Skeleton height={200} />
                                        </Col>
                                    </Col>
                                    <Col lg={5}>
                                        <Skeleton height={400} />
                                    </Col>
                                </>
                            ) : (
                                <>
                                    {/* random artical right side  */}

                                    <Col lg={7}>
                                        <ScrollAnimation animateIn="zoomIn" animateOnce={true} className="random-article-right random-article-right-sec2" duration={2}>
                                            {/* <div className="random-article-right random-article-right-sec2"> */}
                                            {/* article img */}

                                            <img onClick={() => (window.location.href = "/ArticleDetails/" + this.state.RandomArticle[0].id)} className="mainArticalImg" src={Global.image_link + this.state.RandomArticle[0].image} alt="l" />
                                            {/* article img end */}

                                            <div className="random-article-detalis">
                                                {/* article name  */}
                                                <a href={"/ArticleDetails/" + this.state.RandomArticle[0].id}>
                                                    <h2>{this.state.RandomArticle[0].title}</h2>
                                                </a>
                                                {/* end article name  */}

                                                {/* article detailes */}
                                                <div className="article-paragraph">
                                                    <span>{this.MyComponent(this.state.RandomArticle[0].descr)}</span>
                                                </div>

                                                {/* time to read and date  */}
                                                <div className="likeAndTime">
                                                    <div className="timeToRead-ramdom-article">
                                                        <span>{this.state.RandomArticle[0].date}</span>
                                                        <span>تحتاج {this.state.RandomArticle[0].time_to_read} للقراءة </span>
                                                    </div>
                                                    <div
                                                        className="numImgLike"
                                                        onClick={() => {
                                                            this.addLike(this.state.RandomArticle[0].id);
                                                        }}
                                                    >
                                                        <img src="/img/like(2).png" alt="l" />
                                                        <span>{this.kFormatter(this.state.RandomArticle[0].likes)}</span>
                                                    </div>{" "}
                                                </div>

                                                {/* time to read and date end */}
                                                {/* article detailes emd  */}
                                            </div>
                                            {/* </div> */}
                                        </ScrollAnimation>

                                        <ScrollAnimation animateIn="zoomIn" animateOnce={true} className="random-article-right random-article-right-sec2" duration={2}>
                                            {/* <div className="random-article-right random-article-right-sec2"> */}
                                            {/* article img  */}

                                            <img className="mainArticalImg" onClick={() => (window.location.href = "/ArticleDetails/" + this.state.RandomArticle[1]?.id)} src={Global.image_link + this.state.RandomArticle[1]?.image} alt="l" />
                                            {/* article img end */}

                                            <div className="random-article-detalis">
                                                {/* article name  */}
                                                <a href={"/ArticleDetails/" + this.state.RandomArticle[1]?.id}>
                                                    <h2>{this.state.RandomArticle[1]?.title}</h2>
                                                </a>
                                                {/* end article name  */}
                                                {/* article detailes */}
                                                <div className="article-paragraph">
                                                    <span>{this.MyComponent(this.state.RandomArticle[1]?.descr)}</span>
                                                </div>

                                                {/* time to read and date  */}
                                                <div className="likeAndTime">
                                                    <div className="timeToRead-ramdom-article">
                                                        <span>{this.state.RandomArticle[1]?.date}</span>
                                                        <span>تحتاج {this.state.RandomArticle[1]?.time_to_read} للقراءة </span>
                                                    </div>
                                                    <div
                                                        className="numImgLike"
                                                        onClick={() => {
                                                            this.addLike(this.state.RandomArticle[1]?.id);
                                                        }}
                                                    >
                                                        <img src="/img/like(2).png" alt="l" />
                                                        <span>{this.kFormatter(this.state.RandomArticle[1]?.likes)}</span>
                                                    </div>{" "}
                                                </div>
                                                {/* time to read and date end */}
                                                {/* article detailes end */}
                                            </div>
                                            {/* </div> */}
                                        </ScrollAnimation>
                                    </Col>
                                    {/* random artical right side  */}
                                    {/* random artical left side  */}
                                    <Col lg={5}>
                                        {/* <div className="random-article-left random-article-left-sec2"> */}
                                        {/* main img  */}
                                        <ScrollAnimation animateIn="zoomIn" animateOnce={true} className="random-article-left random-article-left-sec2" duration={2}>
                                            <a href={"/ArticleDetails/" + this.state.RandomArticle[2]?.id}>
                                                <img className="mainArticalImg" src={Global.image_link + this.state.RandomArticle[2]?.image} alt="l" />
                                            </a>
                                            {/* main img  */}

                                            {/* article name  */}
                                            <a href={"/ArticleDetails/" + this.state.RandomArticle[2]?.id}>
                                                <h2>{this.state.RandomArticle[2]?.title}</h2>
                                            </a>
                                            {/* end article name  */}
                                            {/* article detailes */}
                                            <div className="article-paragraph">
                                                <span>{this.MyComponent(this.state.RandomArticle[2]?.descr)}</span>
                                            </div>

                                            {/* time to read and date  */}
                                            <div className="likeAndTime">
                                                <div className="timeToRead-ramdom-article">
                                                    <span>{this.state.RandomArticle[2]?.date}</span>
                                                    <span>تحتاج {this.state.RandomArticle[2]?.time_to_read} للقراءة </span>
                                                </div>
                                                <div
                                                    className="numImgLike"
                                                    onClick={() => {
                                                        this.addLike(this.state.RandomArticle[2]?.id);
                                                    }}
                                                >
                                                    <img src="/img/like(2).png" alt="l" />
                                                    <span>{this.kFormatter(this.state.RandomArticle[2]?.likes)}</span>
                                                </div>{" "}
                                            </div>
                                            {/* time to read and date end */}
                                            {/* article detailes */}

                                            {/* </div> */}
                                        </ScrollAnimation>
                                    </Col>
                                    {/* random artical left side  end */}
                                </>
                            )}
                        </Row>
                    </Container>
                </div>
                {/* random article sedtion 2 end */}
            </div>
        );
    }

    /** Add Like Start  */

    addLike(id) {
        fetch(Global.api_link + "like_blog", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                id: id,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.get_blogs();
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Add Like End */

    /** Convert Content to HTML */
    MyComponent(desc) {
        return <div dangerouslySetInnerHTML={this.createMarkup(desc)} />;
    }
    createMarkup(desc) {
        return { __html: desc };
    }
    /** Convert Content to HTML End*/

    // format num fun start
    kFormatter(num) {
        return Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k" : Math.sign(num) * Math.abs(num);
    }
    // format num fun end
}

export default Article;
