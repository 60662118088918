import React, { Component } from "react";
import ScrollAnimation from "react-animate-on-scroll";

import { Container, Col, Row, Carousel } from "react-bootstrap";
import Global from "../Global.json";

import ConnectUs from "../blocks/ConnectUs";
import Skeleton from "react-loading-skeleton";

class Tearms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tearms: [],
            tearmsLoader: true,
        };
    }

    componentDidMount() {
        this.get_terms_details();
    }

    /** Get books Start  */

    get_terms_details() {
        fetch(Global.api_link + "get_terms_details", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                machine_name: this.props.match.params.name,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    tearms: result.data,
                    tearmsLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get books End */

    render() {
        return (
            <div className="tearms">
                <Container>
                    {this.state.tearmsLoader ? (
                        <Row>
                            <Col>
                                <Skeleton height={400} />
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col md={12}>
                                <h2 className="tearms-title">{this.state.tearms.title_ar}</h2>
                                <p>{this.MyComponent(this.state.tearms.text_ar)}</p>
                            </Col>
                        </Row>
                    )}
                </Container>
            </div>
        );
    }
    /** Convert Content to HTML */
    MyComponent(desc) {
        return <div dangerouslySetInnerHTML={this.createMarkup(desc)} />;
    }
    createMarkup(desc) {
        return { __html: desc };
    }
    /** Convert Content to HTML End*/
}
export default Tearms;
