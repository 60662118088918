import React, { Component } from "react";
import ScrollAnimation from "react-animate-on-scroll";

import { Container, Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/css/modal-video.min.css";
import ConnectUs from "../blocks/ConnectUs";
import Global from "../Global.json";
import moment from "moment";
import "moment/locale/ar"; // without this line it didn't work
moment.locale("ar");
class RegisterCourse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            active: false,
            activeSelect: 0,
            scrollDown: true,
            courseDetails: {},
            courseSessions: [],
            courseLoader: true,
        };
    }
    componentDidMount() {
        this.get_course_details();
        ///// to hide  scroll down button

        window.addEventListener("scroll", () => {
            this.setState({
                scrollDown: false,
            });
        });
        ///// to hide  scroll down button
    }

    /** Get  get_course_details Start  */

    get_course_details() {
        fetch(Global.api_link + "get_course_details", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                course_id: this.props.match.params.cId,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
                this.setState({
                    courseDetails: result.details,
                    courseSessions: result.sessions,
                    courseLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get course_details End */

    render() {
        // var  courseDataStart = moment(this.state.courseDetails.start_date).diff(moment(), 'days');
        var courseDataStart = moment(this.state.courseDetails?.start_date).endOf("day").fromNow();
        return (
            <div className="RegisterCourse">
                <div className="courseDetails-page">
                    {/*  course details  section   */}

                    <div className="queries">
                        <Container fluid>
                            <Row className="justify-content-space-between">
                                <Col xl={6} lg={6} md={12}>
                                    {/* course title  */}
                                    <Col xs={12} className="no-padd">
                                        <ScrollAnimation animateIn="rotateInDownRight" animateOnce={true} duration={1}>
                                            <div className="ourServices-title">
                                                {this.state.courseLoader ? <Skeleton height={50} width={200} /> : <h3>{this.state.courseDetails?.name}</h3>}

                                                {this.state.courseDetails.tag ? <span className="course-tag">{this.state.courseDetails.tag} </span> : null}
                                            </div>
                                        </ScrollAnimation>
                                    </Col>
                                    {/* end course title  */}
                                    <Row>
                                        {/*  course date  */}

                                        {/* <Col
                                            xl={3}

                                            sm={6}
                                            className=" z-index-top padding-5px"
                                        > */}
                                            {/* {this.state.courseLoader ? (
                                                <Skeleton height={100} />
                                            ) : (
                                                // <ScrollAnimation animateIn="zoomIn" animateOnce={true} className="course-details" duration={2}>

                                                //     <img src="/img/calendar(2).png" alt="l" />
                                                //     <p>{moment(this.state.courseDetails.start_date).format("YYYY/MM/D")}</p>
                                                //     <span> {courseDataStart} </span>

                                                // </ScrollAnimation>
                                                
                                            )} */}
                                        {/* </Col> */}
                                        {/* end course date  */}
                                        {/*  course hours(time)  */}

                                        <Col
                                            xl={4}
                                            //   lg={12}
                                            sm={6}
                                            className=" z-index-top padding-5px"
                                        >
                                            {this.state.courseLoader ? (
                                                <Skeleton height={100} />
                                            ) : (
                                                <ScrollAnimation animateIn="zoomIn" animateOnce={true} className="course-details" duration={2}>
                                                    <img src="/img/layers.png" alt="l" />
                                                    <p> {this.state.courseDetails.classes} حصة</p>
                                                    <span> {this.state.courseDetails.hours} </span>
                                                    {/* </div> */}
                                                </ScrollAnimation>
                                            )}
                                        </Col>
                                        {/* end course hours(time)  */}

                                        {/*  course price  */}

                                        <Col
                                            xl={4}
                                            //   lg={12}
                                            sm={6}
                                            className=" z-index-top padding-5px"
                                        >
                                            {this.state.courseLoader ? (
                                                <Skeleton height={100} />
                                            ) : (
                                                <ScrollAnimation animateIn="zoomIn" animateOnce={true} className="course-details" duration={2}>
                                                    <img src="/img/receipt(1).png" alt="l" />
                                                    <p>
                                                        {" "}
                                                        {+this.state.courseDetails.discount_percentage !== 0 && <span className="price-before-discount">{(this.state.courseDetails.price * +this.state.courseDetails.discount_percentage) / 100 + +this.state.courseDetails.price}</span>} {this.state.courseDetails.price} KD 
                                                    </p>
                                                    {+this.state.courseDetails.discount_percentage === 0 ? null : (
                                                        <span className="course-descount">
                                                            {this.state.courseDetails.discount_percentage}٪ {this.state.courseDetails.discount_text}{" "}
                                                        </span>
                                                    )}
                                                </ScrollAnimation>
                                            )}
                                        </Col>
                                        {/*  end course price  */}

                                        {/*  course level  */}

                                        <Col
                                            xl={4}
                                            //   lg={12}
                                            sm={6}
                                            className=" z-index-top padding-5px"
                                        >
                                            {this.state.courseLoader ? (
                                                <Skeleton height={100} />
                                            ) : (
                                                <ScrollAnimation animateIn="zoomIn" animateOnce={true} className="course-details" duration={2}>
                                                    {/* <div className="course-details"> */}
                                                    <img src="/img/pie-chart.png" alt="l" />
                                                    <p> {this.state.courseDetails.level === "1" ? "مبتدئ" : this.state.courseDetails.level === "2" ? "متوسط" : "متقدم"}</p>
                                                    <span>{this.state.courseDetails.level_text} </span>

                                                    {/* </div> */}
                                                </ScrollAnimation>
                                            )}
                                        </Col>
                                        {/*  end course level  */}
                                        {/*  course type  */}

                                        <Col xl={6} lg={12} md={6} sm={12} className=" z-index-top padding-5px">
                                            {this.state.courseLoader ? (
                                                <Skeleton height={100} />
                                            ) : (
                                                <ScrollAnimation animateIn="zoomIn" animateOnce={true} className="course-details course-details-special" duration={2}>
                                                    {/* <div className="course-details course-details-special"> */}
                                                    <div className="course-details-special-img">
                                                        <img src="/img/computer (1).png" alt="l" />
                                                    </div>
                                                    <div>
                                                        <p> {this.state.courseDetails.type == 1 ? "تدريب اونلاين" : this.state.courseDetails.type == 2 ? "تدريب شخصي" : "تدريب مباشر"}</p>
                                                        <span> {this.state.courseDetails.type == 1 ? "مسجلة بجودة عالية" : this.state.courseDetails.type == 2 ? "" : " "}</span>
                                                    </div>
                                                    {/* </div> */}
                                                </ScrollAnimation>
                                            )}
                                        </Col>
                                        {/* end  course type  */}

                                        <Col xl={6} md={6} lg={12} sm={12} className=" z-index-top padding-5px">
                                            {this.state.courseLoader ? (
                                                <Skeleton height={100} />
                                            ) : (
                                                this.state.courseDetails.certificate_name && (
                                                    <ScrollAnimation animateIn="zoomIn" animateOnce={true} className="course-details course-details-special" duration={2}>
                                                        <div className="course-details-special-img">
                                                            <img src={this.state.courseDetails.certificate_icon === null ? "/img/medal.png" : Global.image_link + this.state.courseDetails.certificate_icon} alt="l" />
                                                        </div>
                                                        <p> {this.state.courseDetails.certificate_name}</p>
                                                        {/* <span>{this.state.courseDetails.certificate_text}</span> */}
                                                    </ScrollAnimation>
                                                )
                                            )}
                                        </Col>

                                        {/* register now  button */}
                                        <Col sm={12} className="register-col-know-more-buttom">
                                            <button
                                                className="know-more-button"
                                                onClick={() => {
                                                   // window.location.href = "/Packages";
                                                   //window.location.href="https://api.whatsapp.com/message/5FQU53BCVME5O1?text= I Want to Register in  Course" + this.state.courseDetails?.name
                                                   window.location.href = "https://wa.me/+96597787321?text=I%20Want%20to%20Register%20in%20%20Course  " + " " + this.state.courseDetails?.name

                                                }}
                                            >
                                                إمتلكها الآن
                                            </button>
                                        </Col>
                                        {/* end  register now  button */}
                                    </Row>
                                </Col>

                                {/* Course model video */}

                                <Col xl={4} lg={5} sm={12}>
                                    {this.state.courseLoader ? (
                                        <Skeleton height={400} />
                                    ) : (this.state.courseDetails.video == null || this.state.courseDetails.video == "") && (this.state.courseDetails.video_link == null || this.state.courseDetails.video_link == "") ? null : (
                                        <>
                                            <ScrollAnimation animateIn="zoomIn" animateOnce={true} className="queries-video" duration={2}>
                                                <div className="queries-video">
                                                    <img src={Global.image_link + this.state.courseDetails.video_image} alt="l" />
                                                    <img  onClick={() => this.setState({ isOpen: true })} src="/img/Group832.png" alt="l" className="PlayVideoImg" />
                                                </div>
                                            </ScrollAnimation>

                                            <ModalVideo
                                                channel="custom"
                                                controls
                                                name="media"
                                                autoplay
                                                isOpen={this.state.isOpen}
                                                url={this.state.courseDetails.video == null || this.state.courseDetails.video == ""  ? this.state.courseDetails.video_link : Global.image_link + this.state.courseDetails.video}
                                                onClose={() => {
                                                    this.setState({ isOpen: false });
                                                }}
                                            />
                                        </>
                                    )}
                                </Col>
                                {/* end Course model video */}

                                {/* points img  */}

                                <div className="queries-points-img">
                                    <img src="/img/lastcut.png" alt="l" />
                                </div>
                                {/* end points img  */}
                            </Row>
                        </Container>

                        {/* queries-circle  img  */}

                        <div className="queries-circle">
                            <img src="/img/Group831cut.png" alt="l" />
                        </div>
                        {/* end queries-circle  img  */}
                    </div>
                    {/*   end course details  section   */}

                    {/* what you will learn in this course  section */}
                    <div className="whatLearn-forWho" id="book-now">
                        <Container fluid>
                            <Row className="justify-content-center">
                                {/* main description */}
                                <Col lg={6}>
                                    <div className="ourServices-paragraph">{this.state.courseLoader ? <Skeleton height={150} /> : <p>{this.MyComponent(this.state.courseDetails.descr)}</p>}</div>
                                </Col>
                                {/* end main description */}
                            </Row>
                            <Row className="justify-content-center">
                                {/* for who this course */}
                                <Col xl={4} md={6} className=" z-index-top padding-5px">
                                    {this.state.courseLoader ? (
                                        <Skeleton height={300} />
                                    ) : (
                                        <ScrollAnimation animateIn="zoomIn" animateOnce={true} duration={2} className="course-details">
                                            {/* <div className="course-details"> */}
                                            <img src="/img/group.png" alt="l" />
                                            <p>لمن هذه الدورة</p>

                                            <span>{this.MyComponent(this.state.courseDetails.course_target)}</span>
                                            {/* </div> */}
                                        </ScrollAnimation>
                                    )}
                                </Col>
                                {/* end for who this course */}

                                {/* what you will learn */}
                                <Col xl={4} md={6} className=" z-index-top padding-5px">
                                    {this.state.courseLoader ? (
                                        <Skeleton height={300} />
                                    ) : (
                                        <ScrollAnimation animateIn="zoomIn" animateOnce={true} duration={2} className="course-details">
                                            {/* <div className="course-details"> */}
                                            <img src="/img/book(2).png" alt="l" />
                                            <p>سوف تتعلم في هذة الدورة</p>
                                            <span>{this.MyComponent(this.state.courseDetails.course_goals)}</span>
                                            {/* </div> */}
                                        </ScrollAnimation>
                                    )}
                                </Col>
                                {/* end what you will learn */}

                                {/* plan of course */}
                                <Col xl={8} md={12}>
                                    <div className="register-course-plan-course">
                                        <Row className="justify-content-center">
                                            {this.state.courseLoader ? (
                                                <Col sm={12} className="no-padd">
                                                    <Skeleton height={300} />
                                                </Col>
                                            ) : (
                                                <Col sm={6} className="no-padd">
                                                    <div className="register-course-plan-course-right">
                                                        <div className="register-course-plan-course-title">
                                                            <img src="/img/book(1).png" alt="l" />
                                                            <span>مخطط الدورة</span>
                                                        </div>
                                                        <ul>
                                                            {this.state.courseSessions.map((item, index) => {
                                                                return (
                                                                    <li
                                                                        key={index}
                                                                        className={this.state.activeSelect == index ? "active" : ""}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                activeSelect: index,
                                                                            });
                                                                        }}
                                                                    >
                                                                        <div className="register-course-plan-dropdown-title dropLeft-frstchild">
                                                                            <div>
                                                                                <img src="/img/Ellipse98.png" alt="1" />
                                                                                <span>{item.name}</span>
                                                                            </div>

                                                                            <div>
                                                                                <span>{moment(item.start_date).format("YYYY/MM/D")}</span>
                                                                                {this.state.activeSelect == item.id ? <img src="/img/Path177.png" alt="1" /> : <img src="/img/Path178.png" alt="1" />}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>
                                                </Col>
                                            )}
                                            <Col sm={6} className="no-padd">
                                                {this.state.courseLoader ? null : this.state.courseSessions.length < 1 ? null : (
                                                    <div className="register-course-plan-course-left">
                                                        {this.state.courseSessions[this.state.activeSelect].content.map((item, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <div className="item">
                                                                        <span>{item.name}</span>
                                                                        <span>{moment(item.start_date).format("YYYY/MM/D")}</span>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>

                                <Col lg={12}>
                                    <Row className="justify-content-center">
                                        {/* RegisterCourse social media */}
                                        <Col xl={4} lg={6} md={7}>
                                            <div className="RegisterCourse-socialMedia">
                                                <div className="share-training">
                                                    <a href="https://www.facebook.com">شارك التدريب</a>
                                                </div>

                                                <div className="RegisterCourse-socialMedia-socialMedia">
                                                    {this.props.socialMediaLoader
                                                        ? null
                                                        : this.props.social.map((item, index) => {
                                                            return (
                                                                <ScrollAnimation animateIn="bounce" animateOnce={true} duration={item.id} key={index}>
                                                                    <div>
                                                                        <a href={item.link}>
                                                                            <i className={"fa " + item.icon}></i>
                                                                        </a>
                                                                    </div>
                                                                </ScrollAnimation>
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                        </Col>
                                        {/* end RegisterCourse social media */}

                                        {/* register now  button */}

                                        <Col xl={4} lg={6} md={5} sm={6} className="queries-col-know-more-buttom">
                                            <button
                                                className="know-more-button"
                                                onClick={() => {
                                                    //window.location.href = "/Packages";
                                                   //window.location.href="https://api.whatsapp.com/message/5FQU53BCVME5O1?text= I Want to Register in  Course" + this.state.courseDetails?.name
                                                   window.location.href = "https://wa.me/+96597787321?text=I%20Want%20to%20Register%20in%20%20Course  " + " " + this.state.courseDetails?.name
                                                }}
                                            >
                                                إمتلكها الآن
                                            </button>
                                        </Col>
                                        {/* end  register now  button */}
                                    </Row>
                                </Col>
                                {/* end plan of course */}
                            </Row>
                        </Container>
                    </div>

                    {/* end what you will learn in this course  section */}
                </div>
            </div>
        );
    }
    /** Convert Content to HTML */
    MyComponent(desc) {
        return <div dangerouslySetInnerHTML={this.createMarkup(desc)} />;
    }
    createMarkup(desc) {
        return { __html: desc };
    }
    /** Convert Content to HTML End*/
}
export default RegisterCourse;
