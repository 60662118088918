import React, { Component } from "react";

import { Container, Col, Row } from "react-bootstrap";
// import CarouselTraining from "react-elastic-carousel";
import Global from "../Global.json";

import ScrollAnimation from "react-animate-on-scroll";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Skeleton from "react-loading-skeleton";
class Training extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };
    render() {
        return (
            /// Training section
            <div className="Training" id="trainingOnline">
                <Container fluid>
                    <Row>
                        {/*  Training title  */}

                        <Col xs={12}>
                            {this.props.randomTrainingLoader ? (
                                <Skeleton height={50} width={200} />
                            ) : (
                                <div className="ourServices-title">
                                    {this.props.withIcon ? <img src="/img/computer (1).png" alt="l" /> : null}

                                    <ScrollAnimation animateIn="rotateInDownRight" animateOnce={true} duration={1}>
                                        <h3> {this.props.trainingDescription ? this.props.trainingDescription.title : this.props.trainingDescriptionOnline.name}</h3>
                                    </ScrollAnimation>
                                </div>
                            )}
                        </Col>

                        {/* end Training title  */}

                        {/*  Training description  */}
                        <Col xs={12}>
                            {this.props.randomTrainingLoader ? (
                                <Skeleton height={60} />
                            ) : (
                                <div className="ourServices-paragraph">
                                    <p>{this.props.trainingDescription ? this.MyComponent(this.props.trainingDescription.description) : this.MyComponent(this.props.trainingDescriptionOnline.descr)}</p>
                                </div>
                            )}
                        </Col>
                        {/* end Training description  */}

                        {/* carousel  Training   */}
                        <Col sm={12}>
                            {this.props.randomTrainingLoader ? (
                                <Row>
                                    <Col md={4}>
                                        <Skeleton height={300} width="80%" />
                                    </Col>
                                    <Col md={4}>
                                        <Skeleton height={300} width="80%" />
                                    </Col>
                                    <Col md={4}>
                                        <Skeleton height={300} width="80%" />
                                    </Col>
                                </Row>
                            ) : !this.props.courses ? (
                                <Carousel responsive={this.responsive} showDots={true} containerClass={this.props.coursesOnline.length < 3 ? "directionRtl" : ""}>
                                    {this.props.coursesOnline.map((item, index) => {
                                        return (
                                            <a href={"/registerCourse/" + item.id} className="training-img" key={index}>
                                                {item.tag === null || item.tag === "" ? null : (
                                                    <div className="commingSoon-label">
                                                        <div>
                                                            <span>{item.tag}</span>
                                                        </div>
                                                        {/* <img src="/img/Subtraction11.png" alt="l" /> */}
                                                    </div>
                                                )}
                                                <img src={Global.image_link + item.image} alt="l" className="training-img-img" />

                                                <p>{item.name}</p>
                                            </a>
                                        );
                                    })}
                                </Carousel>
                            ) : (
                                <Carousel responsive={this.responsive} showDots={true} containerClass={this.props.courses.length < 3 ? "directionRtl" : ""}>
                                    {this.props.courses.map((item, index) => {
                                        return (
                                            <a href={"/registerCourse/" + item.id} className="training-img" key={index}>
                                                {item.tag === null || item.tag === "" ? null : (
                                                    <div className="commingSoon-label">
                                                        <div>
                                                            <span>{item.tag}</span>
                                                        </div>
                                                        {/* <img src="/img/Subtraction11.png" alt="l" /> */}
                                                    </div>
                                                )}
                                                <img src={Global.image_link + item.image} alt="l" className="training-img-img" />

                                                <p>{item.name}</p>
                                            </a>
                                        );
                                    })}
                                </Carousel>
                            )}
                        </Col>
                        {/* end carousel  Training   */}
                        {this.props.withIcon ? (
                            <Col sm={12} className="Training-col-know-more-buttom">
                                <button
                                    className="know-more-button"
                                    onClick={() => {
                                        window.location.href = "/trainingSchemeOnline";
                                    }}
                                >
                                    اكتشف اكثر
                                </button>
                            </Col>
                        ) : null}
                    </Row>
                </Container>

                {/* queries-circle  img  */}
                <div className="queries-circle">
                    <img src="/img/Ellipse90half.png" alt="l" />
                </div>
                {/* end queries-circle  img  */}
            </div>
            ///end Training section
        );
    }
    /** Convert Content to HTML */
    MyComponent(desc) {
        return <div dangerouslySetInnerHTML={this.createMarkup(desc)} />;
    }
    createMarkup(desc) {
        return { __html: desc };
    }
    /** Convert Content to HTML End*/
}
export default Training;
