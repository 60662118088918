import React, { Component } from "react";

import { Container, Col, Row } from "react-bootstrap";
import "../../node_modules/react-modal-video/css/modal-video.min.css";
import ConnectUs from "../blocks/ConnectUs";
import ScrollAnimation from "react-animate-on-scroll";
import Global from "../Global.json";
import Skeleton from "react-loading-skeleton";
class TrainingScheme extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            scrollDown: true,
            personalTrainingDetails: {},
            personalTrainingCourses: [],
            personalTrainingLoader: true,
        };
    }

    componentDidMount() {
        this.get_training_details();
        ///// to hide  scroll down button

        window.addEventListener("scroll", () => {
            this.setState({
                scrollDown: false,
            });
        });
        ///// to hide  scroll down button
    }

    /** Get personal_training_details Start  */

    get_training_details() {
        fetch(Global.api_link + "get_training_details", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                training_type: 2,
                course_limit: 0,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    personalTrainingDetails: result.details,
                    personalTrainingCourses: result.courses,
                    personalTrainingLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get personal_training_details End */

    render() {
        return (
            <>
                <div className="Training-page">
                    {this.state.personalTrainingLoader ? (
                        <Skeleton height={400} />
                    ) : (
                        <div className="Training-landing-page">
                            {/* Training-landing-page-img section  */}
                            <div
                                className="Training-scheme-landing-page-img"
                                style={{
                                    background: `url(${Global.image_link + this.state.personalTrainingDetails.slide_image}) no-repeat`,
                                }}
                            >
                                <div className="training-landing-title">
                                    <ScrollAnimation animateIn="pulse" animateOnce={true} duration={1}>
                                        <h2>{this.MyComponent(this.state.personalTrainingDetails.slide_text)}</h2>
                                    </ScrollAnimation>
                                </div>
                            </div>
                            {/* end Training-landing-page-img section  */}
                        </div>
                    )}

                    {/* personal training section  */}

                    {/* <div className="personal-training"> */}
                    <div className="training-scheme-personal-training" id="personal-training">
                        <div className="queries">
                            <Container fluid>
                                <Row className="justify-content-space-between">
                                    {/*  personal training title  */}
                                    <Col xs={12}>
                                        {this.state.personalTrainingLoader ? (
                                            <Skeleton height={50} width={200} style={{ float: "right" }} />
                                        ) : (
                                            <ScrollAnimation animateIn="rotateInDownRight" animateOnce={true} duration={1}>
                                                <div className="ourServices-title">
                                                    <img src="/img/video-call.png" alt="l" />
                                                    <h3>{this.state.personalTrainingDetails.name}</h3>
                                                </div>
                                            </ScrollAnimation>
                                        )}
                                    </Col>
                                    {/* end  personal training title  */}
                                    {/*  personal training description */}

                                    <Col lg={6}>
                                        <div className="ourServices-paragraph">{this.state.personalTrainingLoader ? <Skeleton height={100} /> : <p> {this.MyComponent(this.state.personalTrainingDetails.descr)}</p>}</div>
                                    </Col>
                                    {/* end  personal training description */}
                                </Row>
                            </Container>
                        </div>
                    </div>

                    {/* end personal training section  */}

                    {/*  structure training section  */}

                    <div className="structure-training">
                        <div className="personal-training">
                            <div className="queries">
                                <Container fluid>
                                    <Row className="justify-content-center">
                                        {/*  personal training title  */}
                                        <Col xs={12}>
                                            <div className="ourServices-title">
                                                <h3>بنية التدريب</h3>
                                            </div>
                                        </Col>
                                        {/* end  personal training title  */}
                                        {/*  personal training description */}

                                        <Col lg={6}>
                                            <div className="ourServices-paragraph">
                                                <p>قد يكون نجاحك عظيما وطاغيا في مجال تخصصك وغالبا يكون ذلك على حساب ادوار اخرى في حياتك قد تعجب بنفسك وتتضخم الانا بسبب تلك النجاحات لكنك حتما ستعيش تعيسا ولا تحب نفسك مالم توازن نجاحاتك ببقية أدوارك في الحياة</p>
                                            </div>
                                        </Col>
                                        {/* end  personal training description */}

                                        {/*  personal training types */}

                                        <Col lg={12} className="z-index-top">
                                            <Row className="justify-content-center">
                                                <Col xl={2} lg={3} sm={6} xs={10} className="padding-5px padding-3px">
                                                    <ScrollAnimation animateIn="rotateInDownRight" animateOnce={true} className="queries-info select-axis" duration={1.5}>
                                                        {/* <div className="queries-info select-axis"> */}
                                                        <h6>اختر المحور</h6>
                                                        <div>
                                                            <img src="/img/speech.png" alt="l" />
                                                            <span>تدريب القادة</span>
                                                        </div>
                                                        <div>
                                                            <img src="/img/family.png" alt="l" />
                                                            <span>تربية الأبناء</span>
                                                        </div>
                                                        <div>
                                                            <img src="/img/couple(1).png" alt="l" />
                                                            <span>العلاقات الزوجية</span>
                                                        </div>
                                                        <div>
                                                            <img src="/img/connected.png" alt="l" />
                                                            <span>العلاقات</span>
                                                        </div>
                                                        {/* </div> */}
                                                    </ScrollAnimation>
                                                </Col>
                                                <Col xl={2} lg={3} sm={6} xs={10} className="padding-5px padding-3px">
                                                    <ScrollAnimation animateIn="rotateInDownRight" animateOnce={true} className="queries-info" duration={2}>
                                                        {/* <div className="queries-info"> */}
                                                        <h6>تحديد جدول التدريب</h6>

                                                        <img src="/img/calendar(2).png" alt="l" />
                                                        <p>يمكنك تحديد الايام والساعات التي تريدها</p>
                                                        {/* </div> */}
                                                    </ScrollAnimation>
                                                </Col>
                                                <Col xl={2} lg={3} sm={6} xs={10} className="padding-5px padding-3px">
                                                    <ScrollAnimation animateIn="rotateInDownRight" animateOnce={true} className="queries-info numOfSessions" duration={2.5}>
                                                        {/* <div className="queries-info numOfSessions"> */}

                                                        <h6>تحديد مدة وعدد الجلسات</h6>

                                                        <img src="/img/layers.png" alt="l" />
                                                        <p>يمكنك تحديد عدد الجلسات</p>
                                                        <img src="/img/chronometer.png" alt="l" />
                                                        <p>علما ان مدة الجلسة الواحدة ١٢٠ دقيقة</p>

                                                        {/* </div> */}
                                                    </ScrollAnimation>
                                                </Col>
                                                <Col xl={2} lg={3} sm={6} xs={10} className="padding-5px padding-3px ">
                                                    <ScrollAnimation animateIn="rotateInDownRight" animateOnce={true} className="queries-info" duration={3}>
                                                        {/* <div className="queries-info"> */}
                                                        <h6>الوصول الى فهم أعمق للشخصية وأساليب التواصل مع الاخرين</h6>
                                                        <img src="/img/Group7831.png" alt="l" className="img-lamp" />
                                                        {/* </div> */}
                                                    </ScrollAnimation>
                                                </Col>
                                                <Col sm={12} className="queries-col-know-more-buttom">
                                                    <button className="know-more-button">ابدأ الأن</button>
                                                </Col>
                                            </Row>
                                        </Col>
                                        {/* end  personal training types */}
                                    </Row>
                                </Container>

                                {/* points img  */}

                                <div className="queries-points-img">
                                    <img src="/img/last.png" alt="l" />
                                </div>
                                {/* end points img  */}

                                {/* points img  */}

                                <div className="queries-points-img-cut">
                                    <img src="/img/lastcut.png" alt="l" />
                                </div>
                                {/* end points img  */}
                            </div>
                        </div>
                    </div>
                    {/* end structure training section  */}
                    {/* personal training section  */}

                    <div className="all-personal-training" id="personal-training">
                        <div className="queries all-training">
                            <Container fluid>
                                <Row className="justify-content-space-between">
                                    {/*  personal training title  */}
                                    <Col xs={12}>
                                        <ScrollAnimation animateIn="rotateInDownRight" animateOnce={true} duration={1}>
                                            <div className="ourServices-title">
                                                <img src="/img/presentation.png" alt="l" />
                                                <h3>جميع التدريبات</h3>
                                            </div>
                                        </ScrollAnimation>
                                    </Col>
                                    {/* end  personal training title  */}

                                    {/*  personal training types */}

                                    <Col lg={12} className="z-index-top">
                                        <Row>
                                            {this.state.personalTrainingLoader ? (
                                                <>
                                                    <Col lg={2} md={3} sm={3} xs={6} className="padding-5px">
                                                        <Skeleton height={150} />
                                                    </Col>
                                                    <Col lg={2} md={3} sm={3} xs={6} className="padding-5px">
                                                        <Skeleton height={150} />
                                                    </Col>
                                                    <Col lg={2} md={3} sm={3} xs={6} className="padding-5px">
                                                        <Skeleton height={150} />
                                                    </Col>
                                                    <Col lg={2} md={3} sm={3} xs={6} className="padding-5px">
                                                        <Skeleton height={150} />
                                                    </Col>
                                                    <Col lg={2} md={3} sm={3} xs={6} className="padding-5px">
                                                        <Skeleton height={150} />
                                                    </Col>
                                                </>
                                            ) : (
                                                this.state.personalTrainingCourses.map((item, index) => {
                                                    return (
                                                        <Col lg={2} md={3} sm={3} xs={6} className="padding-5px">
                                                            <ScrollAnimation animateIn="rotateInDownRight" animateOnce={true} duration={2}>
                                                                <div
                                                                    className="queries-info"
                                                                    onClick={() => {
                                                                        window.location.href = "/registerCourse/" + item.id;
                                                                    }}
                                                                >
                                                                    <img src={Global.image_link + item.icon} alt="l" />
                                                                    <p>{item.name}</p>
                                                                </div>
                                                            </ScrollAnimation>
                                                        </Col>
                                                    );
                                                })
                                            )}
                                        </Row>
                                    </Col>
                                    {/* end  personal training types */}
                                </Row>
                            </Container>
                        </div>
                    </div>
                    {/* end personal training section  */}
                </div>
            </>
        );
    }

    /** Convert Content to HTML */
    MyComponent(desc) {
        return <div dangerouslySetInnerHTML={this.createMarkup(desc)} />;
    }
    createMarkup(desc) {
        return { __html: desc };
    }
    /** Convert Content to HTML End*/
}
export default TrainingScheme;
