import React, { Component } from "react";

import { Container, Col, Row } from "react-bootstrap";
import "../../node_modules/react-modal-video/css/modal-video.min.css";
import ConnectUs from "../blocks/ConnectUs";
import ScrollAnimation from "react-animate-on-scroll";
import Global from "../Global.json";
import Skeleton from "react-loading-skeleton";
class TrainingSchemeOnline extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            scrollDown: true,
            onlineTrainingCourses: [],
            onlineTrainingDetails: {},
            onlineTrainingLoader: true,
        };
    }

    componentDidMount() {
        this.get_training_details();
        ///// to hide  scroll down button

        window.addEventListener("scroll", () => {
            this.setState({
                scrollDown: false,
            });
        });
        ///// to hide  scroll down button
    }

    /** Get online_training_details Start  */

    get_training_details() {
        fetch(Global.api_link + "get_training_details", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                training_type: 1,
                course_limit: 0,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    onlineTrainingDetails: result.details,
                    onlineTrainingCourses: result.courses,
                    onlineTrainingLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get online_training_details End */

    render() {
        return (
            <>
                <div className="Training-page">
                    {this.state.onlineTrainingLoader ? (
                        <Skeleton height={400} />
                    ) : (
                        <div className="Training-landing-page">
                            {/* Training-landing-page-img section  */}
                            <div className="Training-scheme-landing-page-img" style={{ background: `url(${Global.image_link + this.state.onlineTrainingDetails.slide_image}) no-repeat` , backgroundSize : "contain" }}>
                                <div className="training-landing-title">
                                    <ScrollAnimation animateIn="pulse" animateOnce={true} duration={1}>
                                        <h2>{this.MyComponent(this.state.onlineTrainingDetails.slide_text)}</h2>
                                    </ScrollAnimation>
                                </div>
                            </div>
                            {/* end Training-landing-page-img section  */}
                        </div>
                    )}

                    {/* personal training section  */}

                    {/* <div className="personal-training"> */}
                    <div className="training-scheme-personal-training" id="personal-training">
                        <div className="queries">
                            <Container fluid>
                                <Row className="justify-content-space-between">
                                    {/*  personal training title  */}
                                    <Col xs={12}>
                                        {this.state.onlineTrainingLoader ? (
                                            <Skeleton height={50} width={200} style={{ float: "right" }} />
                                        ) : (
                                            <ScrollAnimation animateIn="rotateInDownRight" animateOnce={true} duration={1}>
                                                <div className="ourServices-title">
                                                    <img src="/img/video-call.png" alt="l" />
                                                    <h3>{this.state.onlineTrainingDetails.name}</h3>
                                                </div>
                                            </ScrollAnimation>
                                        )}
                                    </Col>
                                    {/* end  personal training title  */}
                                    {/*  personal training description */}

                                    <Col lg={6}>
                                        <div className="ourServices-paragraph">{this.state.onlineTrainingLoader ? <Skeleton height={100} /> : <p>{this.MyComponent(this.state.onlineTrainingDetails.descr)}</p>}</div>
                                    </Col>
                                    {/* end  personal training description */}
                                </Row>
                            </Container>
                        </div>
                    </div>

                    {/* end personal training section  */}

                    {/* personal training section  */}

                    <div className="all-personal-training personal-online" id="personal-training">
                        <div className="queries all-training">
                            <Container fluid>
                                <Row className="justify-content-center">
                                    {/*  personal training title  */}
                                    <Col xs={12}>
                                        <div className="ourServices-title">
                                            <h3>جميع التدريبات</h3>
                                        </div>
                                    </Col>
                                    {/* end  personal training title  */}

                                    {/*  personal training types */}

                                    <Col lg={12} className="z-index-top">
                                        <Row>
                                            {this.state.onlineTrainingLoader ? (
                                                <>
                                                    <Col lg={2} md={3} sm={3} xs={6} className="padding-5px">
                                                        <Skeleton height={150} />
                                                    </Col>
                                                    <Col lg={2} md={3} sm={3} xs={6} className="padding-5px">
                                                        <Skeleton height={150} />
                                                    </Col>
                                                    <Col lg={2} md={3} sm={3} xs={6} className="padding-5px">
                                                        <Skeleton height={150} />
                                                    </Col>
                                                    <Col lg={2} md={3} sm={3} xs={6} className="padding-5px">
                                                        <Skeleton height={150} />
                                                    </Col>
                                                    <Col lg={2} md={3} sm={3} xs={6} className="padding-5px">
                                                        <Skeleton height={150} />
                                                    </Col>
                                                </>
                                            ) : (
                                                this.state.onlineTrainingCourses.map((item, index) => {
                                                    return (
                                                        <Col lg={2} md={3} sm={3} xs={6} className="padding-5px">
                                                            <ScrollAnimation animateIn="rotateInDownRight" animateOnce={true} duration={2}>
                                                                <div
                                                                    className="queries-info"
                                                                    onClick={() => {
                                                                        window.location.href = "/registerCourse/" + item.id;
                                                                    }}
                                                                >
                                                                    <img src={Global.image_link + item.icon} alt="l" />
                                                                    <p>{item.name}</p>
                                                                </div>
                                                            </ScrollAnimation>
                                                        </Col>
                                                    );
                                                })
                                            )}
                                        </Row>
                                    </Col>
                                    {/* end  personal training types */}
                                </Row>
                            </Container>
                        </div>
                    </div>
                    {/* end personal training section  */}
                </div>
            </>
        );
    }
    /** Convert Content to HTML */
    MyComponent(desc) {
        return <div dangerouslySetInnerHTML={this.createMarkup(desc)} />;
    }
    createMarkup(desc) {
        return { __html: desc };
    }
    /** Convert Content to HTML End*/
}
export default TrainingSchemeOnline;
