import React, { Component } from "react";
import "./App.css";
import { Route, BrowserRouter } from "react-router-dom";
import Header from "./blocks/Header";
import Home from "./pages/Home";
import Footer from "./blocks/Footer";
import TrainingPage from "./pages/TrainingPage";
import ConnectUs from "./blocks/ConnectUs";
import TrainingScheme from "./pages/TrainingScheme";
import CourseDetails from "./pages/CourseDetails";
import RegisterCourse from "./pages/RegisterCourses";
import WhyChooseUs from "./pages/WhyChooseUs";
import Pachages from "./pages/Packages";
import Publications from "./pages/Publications";
import WorkExperience from "./pages/WorkExperience";
import BookPage from "./pages/BookPage";
import TrainingSchemeOnline from "./pages/TrainingSchemeOnline";
import Global from "./Global.json";
import Tearms from "./pages/Tearms";
import Article from "./pages/Article";
import Exam from "./pages/exam";
import Questions from "./pages/Questions";
import Result from "./pages/Result";
import ArticleDetails from "./pages/ArticleDetails";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headerLoader: true,
      categoryLoader: true,
      activeNavBorder: 0,
      socialMedia: [],
      socialMediaLoader: true,
    };
  }

  componentWillMount() {
    this.get_media();
  }

  /** Get media Start  */

  get_media() {
    fetch(Global.api_link + "get_media", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          socialMedia: result.data,
          socialMediaLoader: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /** Get media End */

  render() {
    return (
      <div className="App">
        <Header
          activeNavBorder={this.state.activeNavBorder}
          socialMedia={this.state.socialMedia}
          socialMediaLoader={this.state.socialMediaLoader}
        />
        <BrowserRouter basename="/">
          <Route path="/" component={Home} exact />
          <Route path="/training" component={TrainingPage} />
          <Route path="/trainingScheme" component={TrainingScheme} />
          <Route path="/Exam" component={Exam} />
          <Route path="/Questions" component={Questions} />
          <Route path="/courseDetails/:cId" component={CourseDetails} />
          <Route
            path="/registerCourse/:cId"
            render={(props) => (
              <RegisterCourse
                {...props}
                social={this.state.socialMedia}
                socialMediaLoader={this.state.socialMediaLoader}
              />
            )}
          />

        
          <Route
            path="/WhyChooseUs"
            render={(props) => (
              <WhyChooseUs
                {...props}
                social={this.state.socialMedia}
                socialMediaLoader={this.state.socialMediaLoader}
              />
            )}
          />
          <Route path="/Packages" component={Pachages} />
          <Route path="/contactUs"  render={(props) => (<ConnectUs  {...props}  social={this.state.socialMedia} socialMediaLoader={this.state.socialMediaLoader} />)}/>
          <Route path="/Publications" component={Publications} />
          <Route path="/TrainingSchemeOnline" component={TrainingSchemeOnline} />
          <Route path="/Article" component={Article} />
          <Route path="/ArticleDetails/:id" component={ArticleDetails} />
          <Route path="/WorkExperience" component={WorkExperience} />
          <Route path="/Result" component={Result} />
          <Route path="/BookPage/:Index" component={BookPage} />
          <Route path="/Tearms/:name" component={Tearms} />

        </BrowserRouter>

        <Footer />
      </div>
    );
  }

  makeActive(num) {
    this.setState({
      activeNavBorder: num,
    });
  }
}

export default App;
