import React, { Component } from "react";

import { Container, Col, Row } from "react-bootstrap";
import Training from "../blocks/Training";
import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/css/modal-video.min.css";
import ConnectUs from "../blocks/ConnectUs";
import ScrollAnimation from "react-animate-on-scroll";
import Global from "../Global.json";
import Skeleton from "react-loading-skeleton";
import moment from "moment";

import "moment/locale/ar"; // without this line it didn't work
moment.locale("ar");
class TrainingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            scrollDown: true,
            landingTrainingData: {},
            landingLoader: true,
            onlineTrainingCourses: [],
            onlineTrainingDetails: {},
            onlineTrainingLoader: true,
            personalTrainingDetails: {},
            personalTrainingCourses: [],
            personalTrainingLoader: true,
            directTrainingDetails: {},
            directTrainingLoader: true,
            directTrainingMainTitle: {},
            all_training_type: []
        };
    }

    componentWillMount() {
        this.get_service_slider();
        this.get_training_details();
        this.get_training_personal();
        this.get_training_direct();
        ///// to hide  scroll down button 
        window.addEventListener("scroll", () => {
            this.setState({
                scrollDown: false,
            });
        });
        ///// to hide  scroll down button
    }
    /** Get service_slider Start  */

    get_service_slider() {
        fetch(Global.api_link + "get_service_slider", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                service_id: 2,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    landingTrainingData: result.data[0],
                    landingLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get service_slider End */

    /** Get online_training_details Start  */

    get_training_details() {
        fetch(Global.api_link + "get_training_details", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                training_type: 1,
                course_limit: 10,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                console.log(result.all_training_type)
                this.setState({
                    onlineTrainingDetails: result.details,
                    all_training_type: result.all_training_type,
                    onlineTrainingCourses: result.courses,
                    onlineTrainingLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get online_training_details End */

    /** Get get_training_personal Start  */

    get_training_personal() {
        fetch(Global.api_link + "get_training_details", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                training_type: 2,
                course_limit: 4,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    personalTrainingDetails: result.details,
                    personalTrainingCourses: result.courses,
                    personalTrainingLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get training_personal End */

    /** Get get_training_direct Start  */

    get_training_direct() {
        fetch(Global.api_link + "get_nearest_training", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                training_type: 3,
                course_limit: 0,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
                this.setState({
                    directTrainingMainTitle: result.details,
                    directTrainingDetails: result.row,
                    directTrainingLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get training_direct End */

    render() {
        //  var  courseDataStart = moment(this.state.directTrainingDetails.start_date).diff(moment(), 'days');
        var courseDataStart = moment(this.state.directTrainingDetails != null ? this.state.directTrainingDetails.start_date : null)
            .endOf("day")
            .fromNow();

        return (
            <div className="Training-page">
                {this.state.landingLoader ? (
                    <Skeleton height={400} />
                ) : (
                    <div className="Training-landing-page">
                        {/* Training-landing-page-img section  */}
                        <div
                            className="Training-landing-page-img"
                            style={{
                                background: `url(${Global.image_link + this.state.landingTrainingData.image}) no-repeat`,
                            }}
                        >
                            <div className="training-landing-title">
                                <h2>{this.MyComponent(this.state.landingTrainingData.text)}</h2>
                            </div>
                        </div>
                        {/* end Training-landing-page-img section  */}

                        {/* training-landing-card */}
                        <Container fluid>
                            <Row className="justify-content-md-center">
                                <Col xl={3} xs={4} className="padding-3px">
                                    <ScrollAnimation animateIn="rotateInDownRight" animateOnce={true} offset={0} duration={2}>
                                        <div
                                            className="training-landing-card"
                                            onClick={() => {
                                                window.location.href = "#trainingOnline";
                                            }}
                                        >
                                            <img src="/img/computer (1).png" alt="l" />
                                            <span> {this.state.all_training_type[0] ? this.state.all_training_type[0].name : null}</span>
                                        </div>
                                    </ScrollAnimation>
                                </Col>
                                <Col xl={3} xs={4} className="padding-3px">
                                    <ScrollAnimation animateIn="zoomIn" animateOnce={true} offset={0} duration={2}>
                                        <div
                                            className="training-landing-card"
                                            onClick={() => {
                                                window.location.href = "#personal-training";
                                            }}
                                        >
                                            <img src="/img/video-call.png" alt="l" />
                                            <span> {this.state.all_training_type[1] ? this.state.all_training_type[1].name : null}</span>
                                        </div>
                                    </ScrollAnimation>
                                </Col>
                                {this.state.all_training_type[2] ?
                                    <Col xl={3} xs={4} className="padding-3px">
                                        <ScrollAnimation animateIn="rotateInDownLeft" animateOnce={true} offset={0} duration={2}>
                                            <div
                                                className="training-landing-card"
                                                onClick={() => {
                                                    window.location.href = "#direct-training";
                                                }}
                                            >
                                                <img src="/img/presentation.png" alt="l" />
                                                <span>  {this.state.all_training_type[2].name}</span>
                                            </div>
                                        </ScrollAnimation>
                                    </Col> : null}
                            </Row>
                        </Container>
                        {/* end training-landing-card */}
                    </div>
                )}

                {/* Training carousel section  */}

                <Training withIcon={true} coursesOnline={this.state.onlineTrainingCourses} trainingDescriptionOnline={this.state.onlineTrainingDetails} randomTrainingLoader={this.state.onlineTrainingLoader} />
                {/* Training carousel section  */}

                {/* personal training section  */}

                <div className="personal-training" id="personal-training">
                    <div className="queries">
                        <Container fluid>
                            <Row className="justify-content-space-between">
                                {/*  personal training title  */}
                                <Col xs={12}>
                                    <ScrollAnimation animateIn="rotateInDownRight" animateOnce={true} duration={1}>
                                        <div className="ourServices-title">
                                            {this.state.personalTrainingLoader ? (
                                                <Skeleton height={50} width={200} />
                                            ) : (
                                                <>
                                                    <img src="/img/video-call.png" alt="l" />
                                                    <h3>{this.state.personalTrainingDetails.name}</h3>
                                                </>
                                            )}
                                        </div>
                                    </ScrollAnimation>
                                </Col>
                                {/* end  personal training title  */}
                                {/*  personal training description */}

                                <Col lg={6}>
                                    <div className="ourServices-paragraph">
                                        {this.state.personalTrainingLoader ? (
                                            <Skeleton height={50} />
                                        ) : (
                                            <p>
                                                {/* {this.state.personalTrainingDetails.descr} */}
                                                {this.MyComponent(this.state.personalTrainingDetails.descr)}
                                            </p>
                                        )}
                                    </div>
                                </Col>
                                {/* end  personal training description */}

                                {/*  personal training types */}

                                <Col lg={7} className="z-index-top">
                                    <Row>
                                        {this.state.personalTrainingLoader ? (
                                            <>
                                                <Col lg={3} md={3} sm={3} xs={6} className="padding-5px">
                                                    <Skeleton height={200} />
                                                </Col>
                                                <Col lg={3} md={3} sm={3} xs={6} className="padding-5px">
                                                    <Skeleton height={200} />
                                                </Col>
                                                <Col lg={3} md={3} sm={3} xs={6} className="padding-5px">
                                                    <Skeleton height={200} />
                                                </Col>
                                                <Col lg={3} md={3} sm={3} xs={6} className="padding-5px">
                                                    <Skeleton height={200} />
                                                </Col>
                                            </>
                                        ) : (
                                            <>
                                                {this.state.personalTrainingCourses.map((item, index) => {
                                                    return (
                                                        <Col lg={3} md={3} sm={3} xs={6} className="padding-5px">
                                                            <ScrollAnimation animateIn="rotateInDownRight" animateOnce={true} duration={2}>
                                                                <div className="queries-info">
                                                                    <img src={Global.image_link + item.image} alt="l" />
                                                                    <p>{item.name}</p>
                                                                </div>
                                                            </ScrollAnimation>
                                                        </Col>
                                                    );
                                                })}
                                            </>
                                        )}
                                        <Col sm={12} className="queries-col-know-more-buttom">
                                            <button
                                                className="know-more-button"
                                                onClick={() => {
                                                    // window.location.href = "/trainingScheme";

                                                    //window.location.href = "https://api.whatsapp.com/message/5FQU53BCVME5O1?text= I Want to Register in  Course" + this.state.personalTrainingDetails.name
                                                    window.location.href = "https://wa.me/+96597787321?text=I%20Want%20to%20Register%20in%20%20Course  " + " " + this.state.personalTrainingDetails.name

                                                }}
                                            >
                                                إمتلكها الآن
                                            </button>
                                        </Col>
                                    </Row>
                                </Col>
                                {/* end  personal training types */}

                                {/*  personal training model video */}
                                {this.state.personalTrainingDetails.video_image !== null ?
                                    <Col lg={4} sm={12}>
                                        {this.state.personalTrainingLoader ? (
                                            <Skeleton height={400} />
                                        ) : (this.state.personalTrainingDetails.video === null || this.state.personalTrainingDetails.video == "") && (this.state.personalTrainingDetails.video_link === null || this.state.personalTrainingDetails.video_link === "") ? null : (
                                            <>
                                                <div className="queries-video">
                                                    <img src={Global.image_link + this.state.personalTrainingDetails.video_image} alt="l" />
                                                    {/* <img onClick={() => this.setState({ isOpen: true })} src="/img/Group832.png" alt="l" /> */}
                                                </div>

                                                <ModalVideo
                                                    channel="custom"
                                                    autoplay
                                                    isOpen={this.state.isOpen}
                                                    url={this.state.personalTrainingDetails.video === null || this.state.personalTrainingDetails.video == "" ? this.state.personalTrainingDetails.video_link : Global.image_link + this.state.personalTrainingDetails.video}
                                                    onClose={() => {
                                                        this.setState({ isOpen: false });
                                                    }}
                                                />
                                            </>
                                        )}
                                    </Col> : null}
                                {/* end  personal training model video */}
                            </Row>
                        </Container>
                        {/* points img  */}

                        <div className="queries-points-img">
                            <img src="/img/Group783.png" alt="l" />
                        </div>
                        {/* end points img  */}

                        {/* queries-circle  img  */}

                        <div className="queries-circle">
                            <img src="/img/Group831cut.png" alt="l" />
                        </div>
                        {/* end queries-circle  img  */}
                    </div>
                </div>
                {/* end personal training section  */}

                {/* direct training section */}

                <div className="direct-training" id="direct-training">
                    <div className="queries">
                        <Container fluid>
                            <Row className="justify-content-space-between">
                                {/* direct training title  */}
                                <Col xs={12}>
                                    <ScrollAnimation animateIn="rotateInDownRight" animateOnce={true} duration={1}>
                                        <div className="ourServices-title">
                                            {this.state.directTrainingLoader ? (
                                                <Skeleton height={50} width={200} />
                                            ) : (
                                                <>
                                                    <img src="/img/presentation.png" alt="l" />
                                                    <h3> {this.state.directTrainingMainTitle.name}</h3>
                                                </>
                                            )}
                                        </div>
                                    </ScrollAnimation>
                                </Col>
                                {/* end direct training title  */}
                                {/* direct training description */}

                                <Col lg={6}>
                                    <div className="ourServices-paragraph">
                                        <p>{this.state.directTrainingLoader ? <Skeleton height={100} /> : this.MyComponent(this.state.directTrainingMainTitle.descr)}</p>
                                    </div>
                                </Col>
                                {/* end direct training description */}
                            </Row>

                            {/*  the next course */}
                            {this.state.directTrainingDetails != null ? (
                                <Row className="justify-content-space-between">
                                    <Col md={6}>
                                        <div className="next-course-paragraph">
                                            {this.state.directTrainingLoader ? <Skeleton height={50} width={200} /> : <h2>{this.state.directTrainingDetails.name}</h2>}
                                            <div>
                                                {this.state.directTrainingLoader ? (
                                                    <Skeleton height={300} />
                                                ) : (
                                                    <span>
                                                        {/* {this.state.directTrainingDetails.descr} */}
                                                        {this.MyComponent(this.state.directTrainingDetails.descr)}
                                                    </span>
                                                )}
                                            </div>
                                            <button
                                                className="know-more-button"
                                                onClick={() => {
                                                    //window.location.href = "/registerCourse/" + this.state.directTrainingDetails.id;
                                                    //window.location.href = "https://api.whatsapp.com/message/5FQU53BCVME5O1?text= I Want to Register in Course " + this.state.directTrainingDetails.name
                                                    window.location.href = "https://wa.me/+96597787321?text=I%20Want%20to%20Register%20in%20%20Course  " + " " + this.state.directTrainingDetails.name
                                                }}
                                            >
                                                إمتلكها الآن
                                            </button>
                                        </div>
                                    </Col>

                                    <Col xl={5} lg={6} md={6}>
                                        <Row>
                                            {/* <Col xl={5} sm={6} className="padding-3px-direct z-index-top">
                                                {this.state.directTrainingLoader ? (
                                                    <Skeleton height={150} />
                                                ) : (
                                                    <ScrollAnimation animateIn="zoomIn" animateOnce={true} duration={2}>
                                                        <div className="course-details">
                                                            <img src="/img/calendar(2).png" alt="l" />
                                                            <p>{moment(this.state.directTrainingDetails.start_date).format("YYYY/MM/D")}</p>
                                                            <span> {courseDataStart} </span>
                                                        </div>
                                                    </ScrollAnimation>
                                                )}
                                            </Col> */}
                                            <Col xl={5} sm={6} className="padding-3px-direct z-index-top">
                                                {this.state.directTrainingLoader ? (
                                                    <Skeleton height={150} />
                                                ) : (
                                                    <ScrollAnimation animateIn="zoomIn" animateOnce={true} duration={2}>
                                                        <div className="course-details">
                                                            <img src="/img/receipt(1).png" alt="l" />

                                                            {this.state.directTrainingDetails.price != 0 ?
                                                                <p>
                                                                    {this.state.directTrainingDetails.discount_percentage !== 0 && <span className="price-before-discount">{this.state.directTrainingDetails.price}  </span>}
                                                                    {this.state.directTrainingDetails.price - (this.state.directTrainingDetails.price * this.state.directTrainingDetails.discount_percentage) / 100} KD

                                                                </p>
                                                                : null}
                                                            <span className="course-descount">
                                                                {this.state.directTrainingDetails.discount_percentage == 0 ? null : (
                                                                    <>
                                                                        {this.state.directTrainingDetails.discount_percentage}٪ {this.state.directTrainingDetails.discount_text}
                                                                    </>
                                                                )}
                                                            </span>
                                                        </div>
                                                    </ScrollAnimation>
                                                )}
                                            </Col>

                                            <Col xl={5} sm={6} className="padding-3px-direct z-index-top">
                                                {this.state.directTrainingLoader ? (
                                                    <Skeleton height={150} />
                                                ) : (
                                                    <ScrollAnimation animateIn="zoomIn" animateOnce={true} duration={2}>
                                                        <div className="course-details">
                                                            <img src="/img/layers.png" alt="l" />
                                                            <p style={{ paddingBottom: "12px" }}> {this.state.directTrainingDetails.classes} حصة</p>
                                                            <span>بمعدل {this.state.directTrainingDetails.hours} ساعة تدريبية </span>
                                                        </div>
                                                    </ScrollAnimation>
                                                )}
                                            </Col>
                                            {/* <Col xl={5} sm={6} className="padding-3px-direct z-index-top">
                                                {this.state.directTrainingLoader ? (
                                                    <Skeleton height={150} />
                                                ) : (
                                                    <ScrollAnimation animateIn="zoomIn" animateOnce={true} duration={2}>
                                                        <div className="course-details">
                                                            <img src="/img/placeholder(2).png" alt="l" />
                                                            <p className="LoactionName">{this.state.directTrainingDetails.location}</p> 
                                                        </div>
                                                    </ScrollAnimation>
                                                )}
                                            </Col> */}

                                            <Col xl={10} sm={12} className="padding-3px-direct z-index-top">
                                                {this.state.directTrainingLoader ? (
                                                    <Skeleton height={150} />
                                                ) : (
                                                    <ScrollAnimation animateIn="zoomIn" animateOnce={true} duration={2}>
                                                        <div className="course-details course-details-special">
                                                            <div className="course-details-special-img">
                                                                <img src={this.state.directTrainingDetails.icon === null ? "/img/medal1.png" : Global.image_link + this.state.directTrainingDetails.icon} alt="l" />
                                                            </div>
                                                            <p>{this.state.directTrainingDetails.certificate_name}</p>
                                                            {/* <span>بعد يومين </span> */}
                                                        </div>
                                                    </ScrollAnimation>
                                                )}
                                            </Col>
                                        </Row>
                                        {/* points img  */}
                                        
                                        {/* end points img  */}
                                    </Col>
                                </Row>
                            ) : null}
                            {/* end  the next course */}
                            <img src="/img/Group783.png" alt="l" className="header-point-img" />
                        </Container>
                    </div>
                </div>

            </div>
        );
    }
    /** Convert Content to HTML */
    MyComponent(desc) {
        return <div dangerouslySetInnerHTML={this.createMarkup(desc)} />;
    }
    createMarkup(desc) {
        return { __html: desc };
    }
    /** Convert Content to HTML End*/
}
export default TrainingPage;
