import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import ProgressBar from 'react-bootstrap/ProgressBar'
import Global from "../Global.json";
import swal from "sweetalert";

class Questions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            scrollDown: true,
            showMore: false,
            Questions: [],
            booksLoader: false,
            activeSlide: null,
            allselectedValue: [],
            AllQues: [],
            selectedIndex: 0,
            allQues: [],
            counter: 0
        };
        this._TogglePrev = this._TogglePrev.bind(this);
        this._ToggleNext = this._ToggleNext.bind(this);

    }


    _ToggleNext() {
        this.setState(prevState => ({
            selectedIndex: prevState.selectedIndex + 1,
        }))
        //this.get_questions() 

        console.log(this.state.selectedIndex , this.state.counter , this.state.allselectedValue.length)
    }

    _TogglePrev() {
        this.setState(prevState => ({
            selectedIndex: prevState.selectedIndex - 1,
        }))

        //this.get_questions()
    }


    tabClicked = (tab) => {
        this.setState({
            selected: 'selected'
        });
    }



    componentDidMount() {
        ///// to hide  scroll down button

        window.addEventListener("scroll", () => {
            this.setState({
                scrollDown: false,
            });
        });
        ///// to hide  scroll down button 
        this.get_questions()
    }

    /** Get  Questions Start  */
    get_questions() {
        fetch(Global.api_link + "getExamQuestions", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                exam_id: 1
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                //console.log(result);
                var ques = [
                    [], [], [], [], [], [], [], [], [], []
                ];
                var activeI = 0;
                var activeIC = 0;
                result.allQues.map((q, i) => {

                    var temp = ques[activeI];
                    temp[activeIC] = q;
                    ques[activeI] = temp;

                    if (activeIC < 9) {

                        activeIC++;
                    } else {
                        activeI++;
                        activeIC = 0;
                    }


                });

                this.setState({
                    qqq: ques,
                    allQues: result.allQues,
                    selectedIndex: 0
                });

                // console.log(ques)
            })
            .catch((error) => {
                console.error(error);
            });



        //get_settings Start 
        fetch(Global.api_link + "get_settings", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
            }),
        })
            .then((response) => response.json())
            .then((result) => {

                this.setState({
                    ExamTitle: result.data.exam_title,
                    ExamTime: result.data.exam_time,
                    ExamQuestionCount: result.data.exam_questions_count,
                    ExamCharacter: result.data.exam_characters,
                    logoLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });

        //get_settings End
    }
    /** Get Questions End */
    handleChange = (index, value, value_name, value_direction) => {
        let array = this.state.allselectedValue;
        this.setState({ counter: array[parseInt(parseInt(this.state.selectedIndex) * 10) + parseInt(index)] ? this.state.counter : this.state.counter + 1 });
        array[parseInt(parseInt(this.state.selectedIndex) * 10) + parseInt(index)] = {
            question_id: this.state.qqq[this.state.selectedIndex][index].id,
            question_name: this.state.qqq[this.state.selectedIndex][index].question,
            answer_id: value,
            answer_name: value_name,
            answer_direction: value_direction
        };
        this.setState({ allselectedValue: array });
        console.log(array, index, value, value_name, parseInt(parseInt(this.state.selectedIndex) * 10) + parseInt(index), this.state.counter + 1, this.state.selectedIndex)
    }

    render() {

        return (
            <div className="ExamWrapper">
                {/*  Exam section  */}
                <div className="ExamStart">
                    <Container fluid>
                        <Row>
                            <Col lg={12}>

                                {/* Exam Details */}
                                <Col xs={12}>
                                    <div className="MeasureStart">
                                        <p>{this.state.ExamTitle}</p>

                                        <ul>
                                            <li>
                                                <div className="TestBox">
                                                    <img src="/img/wall-clock.png" alt="time" />
                                                    <h4>وقت الاختبار  {this.state.ExamTime}</h4>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="TestBox">
                                                    <img src="/img/questiona.png" alt="time" />
                                                    <h4>عدد الاسئلة {this.state.ExamQuestionCount} {/*this.state.allQues.length*/}</h4>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="TestBox">
                                                    <img src="/img/theater-masks.png" alt="time" />
                                                    <h4>{this.state.ExamCharacter} </h4>
                                                </div>
                                            </li>
                                        </ul>

                                    </div>
                                </Col>
                                {/* Exam Details */}
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/*  end  exam section  */}


                <div id="top">
                    <Container fluid>
                        <Row>
                            <Col lg={12}>
                                <ProgressBar now={(this.state.counter / this.state.allQues.length) * 100} label={`${parseInt((this.state.counter / this.state.allQues.length) * 100)}%`} animated={false} striped={false} />
                                {/*<Quiz showDefaultResult={false} appLocale={defaultLocale} quiz={this.state.quiz} showInstantFeedback={true} shuffle={true} continueTillCorrect={false}  /> */}

                                <div className="AllQuestions">
                                    {

                                        this.state.qqq && this.state.qqq[this.state.selectedIndex].length > 0 ?
                                            this.state.qqq[this.state.selectedIndex].map((Ques, index) => {
                                                return (
                                                    <div className="question">
                                                        <div class="containerOuter">
                                                            <h3> -  {Ques.question} </h3>
                                                            <div class="container">
                                                                {Ques.answeres.map((ans, indexAns) => {
                                                                    return (
                                                                        <>
                                                                            <input type="radio" onChange={() => this.handleChange(index, ans.id, ans.name, ans.direction)} checked={this.state.allselectedValue[this.state.selectedIndex] ? this.state.allselectedValue[(parseInt(this.state.selectedIndex) * 10) + parseInt(index)] ? this.state.allselectedValue[(parseInt(this.state.selectedIndex) * 10) + parseInt(index)].answer_id == ans.id ? true : false : false : false} class="hidden" id={"input" + ans.id} name={"inputs" + ans.id} />
                                                                            <label class="entry" for={"input" + ans.id}><div class="circle"></div><div class="entry-label"> {ans.name} </div></label>
                                                                        </>
                                                                    )
                                                                })}
                                                                <div class="highlight"></div>
                                                                <div class="overlay"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : null
                                    }

                                    <br /><br />
                                    <ProgressBar now={(this.state.counter / this.state.allQues.length) * 100} label={`${parseInt((this.state.counter / this.state.allQues.length) * 100)}%`} animated={false} striped={false} />

                                    {this.state.counter !== this.state.allQues.length ?
                                        <div className="Buttons">
                                            {this.state.selectedIndex > 0 ? <a className="prev"   href="#top"  onClick={this._TogglePrev}>السابق</a> : null}
                                            <a className="next" href="#top" onClick={this.state.allselectedValue.length >= (this.state.selectedIndex + 1) * 10 ? this._ToggleNext : ()=> swal("Oops!", "الرجاء الإجابة على جميع الأسئلة ", "error")}>التالي</a>
                                        </div>
                                        :
                                        <div className="Buttons">
                                            {this.state.selectedIndex > 0 ? <a className="prev"   href="#top"  onClick={this._TogglePrev}>السابق</a> : null}
                                            <a className="next" onClick={() => this.Submit()}>إنهاء</a>
                                        </div>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }


    Submit() {
        if (this.state.allQues.length == this.state.allselectedValue.length) {
           
            fetch(Global.api_link + "userSubmitExam", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                },
                body: JSON.stringify({
                    token: Global.token,
                    exam_id: 1,
                    questions: this.state.allselectedValue
                }),
            })
                .then((response) => response.json())
                .then((result) => {
                   // console.log(result)
                    localStorage.setItem("result", result.data)
                    localStorage.setItem("exam_id", result.exam_id)
                    window.location.href = "/Result"
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            swal("Oops!", "الرجاء الإجابة على جميع الأسئلة ", "error");
        }
    }
}
export default Questions;
