import React, { Component } from "react";
import { Container, Col, Row, InputGroup, FormControl, Button } from "react-bootstrap";
import Slider from "react-slick";
import Global from "../Global.json";
import { InlineShareButtons } from 'sharethis-reactjs';
import { FacebookShareButton, LinkedinShareButton, LinkedinIcon, WhatsappIcon, WhatsappShareButton, FacebookIcon, EmailShareButton, EmailIcon, TwitterIcon, TwitterShareButton } from "react-share";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import Skeleton from "react-loading-skeleton";

const localIpUrl = require('local-ip-url');

var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true
};


class Result extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            scrollDown: true,
            showMore: false,
            books: [],
            booksLoader: false,
            activeSlide: null,
            AdsData: [],
            Result: [],
            goToSlide: 0,
            goToSlide1: 0,
            ResultDim: [],
            showShears: false,
            Loader: true,
            resultLoader: false,
            email: "",
            resultText: localStorage.getItem("result")
        };
    }

    componentDidMount() {

        ///// to hide  scroll down button 
        window.addEventListener("scroll", () => {
            this.setState({
                scrollDown: false,
            });
        });
        ///// to hide  scroll down button


        /** Get ads Start  */

        fetch(Global.api_link + "get_advertise", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                let arr = [];
                if (result.data) {
                    result.data.map((item, index) => {
                        arr.push({
                            key: index,
                            content: (
                                <div className="recommendations-card">
                                    {/* <div className="Employee-info-bg"> </div> */}
                                    <img className="thumb-employee-img " src={Global.image_link + item.image} alt="l" />
                                </div>
                            ),
                        });
                    });
                }
                this.setState({
                    AdsData: result.data,
                    newImages: arr.map((item, index) => {
                        return {
                            ...item,
                            onClick: () => this.setState({ goToSlide1: index }),
                        };
                    }),
                });
            })
            .catch((error) => {
                console.error(error);
            });


        fetch(Global.api_link + "get_result", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                symbol: this.state.resultText
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                //  console.log(result);
                this.setState({
                    Result: result.data ? result.data : {},
                    Loader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });


        fetch(Global.api_link + "get_result_dimention", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                exam_id: localStorage.getItem("exam_id")
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                // console.log(result)

                this.setState({

                    ResultDim: result.data,
                    resultLoader: false
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Convert Content to HTML */
    MyComponent(desc) {
        return <div dangerouslySetInnerHTML={this.createMarkup(desc)} />;
    }
    createMarkup(desc) {
        return { __html: desc };
    }
    /** Convert Content to HTML End*/


    render() {
        let  text = this.MyComponent(this.state.Result.result)
        return (
            <div className="ExamWrapper">
                {/*  Exam section  */}
                <div className="ExamStartResult">
                    <p>تهانينا لقد انهيت الاختبار</p>
                </div>
                <div >
                    <Container fluid>
                        <Row>
                            <Col lg={12}>
                                {/* Exam Details */}
                                <Col xs={12}>
                                    <div className="MeasureStart MeasureStartEnd">

                                        <Row className="ReverseColsInMobile"> 
                                            <Col xl={7} lg={7} md={12} sm={12} xs={12} >
                                                <div className="Box">
                                                    <div className="BoxTitle">
                                                        <h3>نمط شخصيتك هو</h3>
                                                        <h4> &#8668; {this.state.Loader ?
                                                            <Skeleton height={30} />
                                                            : this.state.resultText}   &#8669; </h4>
                                                    </div>
                                                </div>

                                                <div className="MeasureResultText">
                                                    <p>
                                                        {text}
                                                    </p>
                                                </div>

                                                <div className="EmailSubs">
                                                    <h2>ارسل لي كامل النتيجة</h2>
                                                    <div>
                                                        <InputGroup className="mb-3 EmailInputGroup submitButt">
                                                            <a class="buttonSub" style={{ verticalAlign: "left" }} onClick={() => this.setState({ showShears: true })}>
                                                                <span>ارسال </span>
                                                            </a>
                                                            <FormControl
                                                                aria-label="Example text with button addon"
                                                                aria-describedby="basic-addon1"
                                                                placeholder="اكتب البريد الإلكتروني هنا"
                                                                onChange={(email) => this.setState({ email: email.target.value })}
                                                            />
                                                        </InputGroup>
                                                    </div>


                                                    {/* modal shear article start */}
                                                    <Modal className="shareModal" show={this.state.showShears} onHide={() => this.setState({ showShears: false })}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>إرسال كامل النتيجة الى البريد الإلكتروني</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <div className="ShareRow">
                                                                <p>
                                                                    هل ترغب باستقبال جميع الباقات والدورات المقدمة من قبل الدكتور محمد العصفور على البريد الالكتروني ليصلك كل جديد
                                                                </p>
                                                                <ul>
                                                                    <li>
                                                                        <a onClick={() => this.SendResultInEmail(1)}>نعم</a>
                                                                    </li>

                                                                    <li>
                                                                        <a onClick={() => this.SendResultInEmail(0)}>لا</a>
                                                                    </li>

                                                                </ul>
                                                            </div>
                                                        </Modal.Body>
                                                    </Modal>
                                                    {/* modal shear article end */}



                                                </div>
                                                <br /><br />
                                                <div>
                                                    <Slider {...settings}>
                                                        {this.state.AdsData.map(slide => {
                                                            return (
                                                                <>
                                                                    <div>
                                                                        <a href={slide.link}>
                                                                            <img src={Global.image_link + slide.image} alt="" />
                                                                        </a>

                                                                    </div>
                                                                </>
                                                            )
                                                        })}

                                                    </Slider>
                                                </div>


                                            </Col>

                                            <Col  xl={5} lg={5} md={12} sm={12} xs={12} >
                                                <div className="Box">
                                                    <div className="BoxTitle">
                                                        <h3>تفضيلات الشخصية</h3>
                                                    </div>

                                                    <div className="progressBarResult"> {/* Start Progresses*/}
                                                        {
                                                        
                                                        this.state.resultLoader ?
                                                            <Skeleton height={50} />
                                                            :
                                                        this.state.ResultDim.map(dim => {

                                                            return (
                                                                <>
                                                                    <div>
                                                                        <h4>{dim.dimention_name}</h4>
                                                                    </div>

                                                                    <div class="TotalProgress">
                                                                        <Row>
                                                                            <Col className="ProgressRight">
                                                                                {/* <div class="progress-bar bg-success" style={{ width: dim.direction == "Right" ? dim.percentage + "%" : "50%" }}>
                                                                                <span>{dim.dimention_right_name}</span>
                                                                            </div> */}

                                                                                <div class="progress">
                                                                                    <div class="progress-bar progress-bar-warning progress-bar-striped" role="progressbar"
                                                                                        aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{ width: dim.direction == "Right" ? (15 - dim.right) == 0 ? "100%" :  (dim.right - dim.left) *100 /7.5  + "%" : 0 }}>

                                                                                    </div>
                                                                                </div>

                                                                                <div >
                                                                                    <h5>{dim.symbol_right}</h5>
                                                                                </div>

                                                                            </Col>

                                                                            <Col className="ProgressLeft">


                                                                                <div class="progress">
                                                                                    <div class="progress-bar progress-bar-info  progress-bar-striped" role="progressbar"
                                                                                        aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{ width: dim.direction == "Left" ? ( 15 - dim.left) == 0 ? "100%" :  (dim.left - dim.right)*100 /7.5   + "%" : 0 }}>

                                                                                    </div>
                                                                                </div>

                                                                                <div >
                                                                                    <h5>{dim.symbol_left}</h5>
                                                                                </div>

                                                                            </Col>

                                                                        </Row>



                                                                    </div>
                                                                </>
                                                            )
                                                        })}

                                                    </div> {/* End Progresses*/}


                                                    <div className="ShareMeasure">{/* Start Share*/}
                                                        <a >اضغط على الرابط لمشاركة المقياس</a>
                                                        {/* <div>
                                                            <InlineShareButtons
                                                                config={{
                                                                    alignment: 'center',  // alignment of buttons (left, center, right)
                                                                    color: 'social',      // set the color of buttons (social, white)
                                                                    enabled: true,        // show/hide buttons (true, false)
                                                                    font_size: 16,        // font size for the buttons
                                                                    labels: 'cta',        // button labels (cta, counts, null)
                                                                    language: 'en',       // which language to use (see LANGUAGES)
                                                                    networks: [           // which networks to include (see SHARING NETWORKS)
                                                                        'whatsapp',
                                                                        'linkedin',
                                                                        'messenger',
                                                                        'facebook',
                                                                        'twitter'
                                                                    ],
                                                                    padding: 10,          // padding within buttons (INTEGER)
                                                                    radius: 4,            // the corner radius on each button (INTEGER)
                                                                    show_total: true,
                                                                    size: 40,             // the size of each button (INTEGER)

                                                                    // OPTIONAL PARAMETERS
                                                                    url: Global.shareExam, // (defaults to current url)
                                                                    image: 'https://bit.ly/2CMhCMC',  // (defaults to og:image or twitter:image)
                                                                    description: 'نتيجتي لمقياس الشخصيه للدكتور محمد العصفور هي  ' . text,       // (defaults to og:description or twitter:description)
                                                                    title: 'مقياس الشخصية للدكتور محمد العصفور',            // (defaults to og:title or twitter:title)
                                                                    message: 'custom email text',     // (only for email sharing)
                                                                    subject: 'custom email subject',  // (only for email sharing)
                                                                    username: 'شارك مقياس الشخصية من الدكتور محمد العصفور' // (only for twitter sharing)
                                                                }}
                                                            />

                                                        </div> */}


                                                        <div className="ShareRow">
                                                            {/* <h5>مشاركة </h5> */}
                                                            <ul>
                                                                <a>
                                                                    <li>
                                                                        
                                                                        <FacebookShareButton
                                                                        
                                                                            url={Global.share_exam_link}
                                                                            separator=":: "
                                                                            title={"مقياس شخصيتي هو " + this.state.resultText}
                                                                            quote={"مقياس شخصيتي هو " + this.state.resultText +  this.state.Result.result}
                                                                        >
                                                                            <FacebookIcon size={32} round />
                                                                        </FacebookShareButton>
                                                                    </li>
                                                                </a>
                                                                <a>
                                                                    <li>
                                                                        <WhatsappShareButton
                                                                            url={Global.share_exam_link}
                                                                            separator=":: "
                                                                            title={"مقياس شخصيتي هو " + this.state.resultText + this.state.Result.result}

                                                                        >
                                                                            <WhatsappIcon size={32} round />
                                                                        </WhatsappShareButton>
                                                                    </li>
                                                                </a>
                                                                <a>
                                                                    <li>
                                                                        <TwitterShareButton
                                                                            url={Global.share_exam_link}
                                                                            // subject={this.state.Details.title}
                                                                            via={this.state.Result.result}
                                                                            separator=":: "
                                                                            title={"مقياس شخصيتي هو " + this.state.resultText}

                                                                        >
                                                                            <TwitterIcon size={32} round />
                                                                        </TwitterShareButton>
                                                                    </li>
                                                                </a>

                                                                <a>
                                                                    <li>
                                                                        <LinkedinShareButton
                                                                            url={Global.share_exam_link}
                                                                            // subject={this.state.Details.title}
                                                                            subject={this.state.Result.result}
                                                                            separator=":: "
                                                                            title={"مقياس شخصيتي هو " + this.state.resultText}
                                                                        >
                                                                            <LinkedinIcon size={32} round />
                                                                        </LinkedinShareButton>
                                                                    </li>
                                                                </a>

                                                            </ul>
                                                        </div>


                                                        {/* <a href="/"><span><img src="/img/Group965.png"></img></span></a>*/}
                                                    </div>{/* End Share*/}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                {/* Exam Details */}
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/*  end  exam section  */}
            </div>
        );
    }


    SendResultInEmail(sub) {
        if (this.state.email !== "") {
            fetch(Global.api_link + "SendResultInEmail", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                },
                body: JSON.stringify({
                    token: Global.token,
                    email: this.state.email,
                    result: this.state.resultText,
                    is_subscribe: sub,
                    ip: localIpUrl()
                }),
            })
                .then((response) => response.json())
                .then((result) => {
                    this.setState({
                        showShears: false
                    })

                })
                .catch((error) => {
                    console.error(error);
                });

        } else {
            swal("Oops!", "الرجاء إدخال البريد الإلكتروني أولاً ", "error");
            this.setState({
                showShears: false 
            })
        }
    }
}
export default Result;
