import React, { Component } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import Counter from "react-number-counter";
import Global from "../Global.json";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            footerData: {},
            footerLoader: false,
            tearms: [],
            tearmsLoader: true,
        };
    }

    componentDidMount() {
        this.get_settings();
        this.get_terms();
        this.Updatevisitors();
    }

    /** Get  Terms Start  */

    get_terms() {
        fetch(Global.api_link + "get_terms", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    tearms: result.data,
                    tearmsLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get Terms End */

    /** Get  settings Start  */

    get_settings() {
        fetch(Global.api_link + "get_settings", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    footerData: result.data,
                    footerLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get settings End */

    /** Get Update visitors Start  */

    Updatevisitors() {
        fetch(Global.api_link + "Updatevisitors", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
            }),
        })
            .then((response) => response.json())
            .then((result) => {})
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get Update visitors End */

    render() {
        return (
            <div className="footer">
                <Container fluid>
                    <Row>
                        <Col md={6} className="footer-right">
                            <Row>
                                <Col lg={3} md={6} sm={6}>
                                    <ul className="footer-list">
                                        <li>
                                            <ScrollAnimation animateOnce={true} animateIn="zoomIn" duration={2} offset={0}>
                                                <a href="/WorkExperience"> السيرة الذاتية</a>
                                            </ScrollAnimation>
                                        </li>
                                        <li>
                                            <ScrollAnimation animateOnce={true} animateIn="zoomIn" duration={2} delay={500} offset={0}>
                                                <a href="/BookPage/0"> المنشورات</a>
                                            </ScrollAnimation>
                                        </li>
                                        <li>
                                            <ScrollAnimation animateOnce={true} animateIn="zoomIn" duration={2} delay={1000} offset={0}>
                                                <a href="/contactUs"> التواصل</a>
                                            </ScrollAnimation>
                                        </li>
                                    </ul>
                                </Col>
                                <Col lg={3} md={6} sm={6}>
                                    <ul className="footer-list">
                                        <li>
                                            {" "}
                                            <ScrollAnimation animateOnce={true} animateIn="zoomIn" duration={2} offset={0} delay={1500}>
                                                <a href="/Article"> المدونة</a>
                                            </ScrollAnimation>
                                        </li>
                                        {/* <li>
                      {" "}
                      <ScrollAnimation
                      animateOnce={true}
                    animateIn="zoomIn"
                    duration={2}
                    delay={2000}
                    offset={0}
                  >
                      <a href="/registerCourse"> الخدمات</a>
                      </ScrollAnimation>
                    </li> */}
                                        {this.state.tearmsLoader ? (
                                            <Skeleton height={20} />
                                        ) : (
                                            this.state.tearms.map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <ScrollAnimation animateOnce={true} animateIn="zoomIn" duration={1} delay={2500} offset={0}>
                                                            <a href={"/Tearms/" + item.machine_name}> {item.title_ar}</a>
                                                        </ScrollAnimation>
                                                    </li>
                                                );
                                            })
                                        )}
                                    </ul>
                                </Col>
                                <Col lg={6} md={12} sm={12} className="footer-Visitors">
                                    <span>عدد زوار الموقع</span>{" "}
                                    <span className="numOf-Visitors">
                                        {" "}
                                        <Counter start={0} end={this.state.footerData.visitors} delay={10} />
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6} className="footer-left">
                            <a href="/">
                                <img src={Global.image_link + this.state.footerData.signature} alt="l" />
                            </a>
                            <p>{this.state.footerData.footer_quote}  </p>
                            <p>{this.state.footerData.copyrights} </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
export default Footer;
