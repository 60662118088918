import React, { Component } from "react";
import ScrollAnimation from "react-animate-on-scroll";

import { Container, Col, Row, Accordion, Card } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import ModalVideo from "react-modal-video";
import ReactPlayer from "react-player";
import "../../node_modules/react-modal-video/css/modal-video.min.css";
import ConnectUs from "../blocks/ConnectUs";
import Global from "../Global.json";
import moment from "moment";
import "moment/locale/ar"; // without this line it didn't work
moment.locale("ar");
class CourseDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            scrollDown: true,
            courseDetails: {},
            courseSessions: [],
            courseLoader: true,
            videoUrl: "",
        };
    }

    componentDidMount() {
        this.get_course_details();

        ///// to hide  scroll down button
        window.addEventListener("scroll", () => {
            this.setState({
                scrollDown: false,
            });
        });
        ///// to hide  scroll down button
    }

    /** Get  get_course_details Start  */

    get_course_details() {
        fetch(Global.api_link + "get_course_details", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                course_id: 1,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    courseDetails: result.details,
                    courseSessions: result.sessions,
                    videoUrl: result.sessions[0].content[0].video === null || result.sessions[0].content[0].video === "" ? result.sessions[0].content[0].video_link : result.sessions[0].content[0].video,
                    courseLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /** Get course_details End */

    render() {
        // var  courseDataStart = moment(this.state.courseDetails.start_date).diff(moment(), 'days');
        var courseDataStart = moment(this.state.courseDetails.start_date).endOf("day").fromNow();

        return (
            <div className="courseDetails-page">
                {/*  course Details  section   */}

                <div className="queries">
                    <Container fluid>
                        <Row className="justify-content-space-between">
                            {/*  the next course */}

                            <Col xl={6} lg={6} md={12}>
                                {/* course Details title  */}
                                <Col xs={12}>
                                    <ScrollAnimation animateIn="rotateInDownRight" animateOnce={true} duration={1}>
                                        <div className="ourServices-title">
                                            {this.state.courseLoader ? <Skeleton height={50} width={200} /> : <h3>{this.state.courseDetails.name}</h3>}
                                            {this.state.courseDetails.tag ? <span className="course-tag">{this.state.courseDetails.tag} </span> : null}
                                        </div>
                                    </ScrollAnimation>
                                </Col>
                                {/* end course Details title  */}

                                <Row>
                                    {/* course date */}
                                    <Col
                                        xl={3}
                                        //   lg={12}
                                        sm={6}
                                        className=" z-index-top padding-5px"
                                    >
                                        {this.state.courseLoader ? (
                                            <Skeleton height={150} />
                                        ) : (
                                            <ScrollAnimation animateIn="zoomIn" animateOnce={true} className="course-details" duration={2}>
                                                {/* <div className="course-details"> */}
                                                <img src="/img/calendar(2).png" alt="l" />
                                                <p>{moment(this.state.courseDetails.start_date).format("YYYY/MM/D")}</p>
                                                <span> {courseDataStart} </span>
                                                {/* </div> */}
                                            </ScrollAnimation>
                                        )}
                                    </Col>
                                    {/* end course date */}

                                    {/* course Time */}

                                    <Col
                                        xl={3}
                                        //   lg={12}
                                        sm={6}
                                        className=" z-index-top padding-5px"
                                    >
                                        {this.state.courseLoader ? (
                                            <Skeleton height={150} />
                                        ) : (
                                            <ScrollAnimation animateIn="zoomIn" animateOnce={true} className="course-details" duration={2}>
                                                {/* <div className="course-details"> */}
                                                <img src="/img/layers.png" alt="l" />
                                                <p> {this.state.courseDetails.classes} حصة</p>
                                                <span>بمعدل {this.state.courseDetails.hours} ساعة تدريبية </span>
                                                {/* </div> */}
                                            </ScrollAnimation>
                                        )}
                                    </Col>
                                    {/* end course Time */}

                                    {/* course price */}

                                    <Col
                                        xl={3}
                                        //   lg={12}
                                        sm={6}
                                        className=" z-index-top padding-5px"
                                    >
                                        {this.state.courseLoader ? (
                                            <Skeleton height={150} />
                                        ) : (
                                            <ScrollAnimation animateIn="zoomIn" animateOnce={true} className="course-details" duration={2}>
                                                {/* <div className="course-details"> */}
                                                <img src="/img/receipt(1).png" alt="l" />
                                                {this.state.courseDetails.price != 0 ?
                                                <p>
                                                    {+this.state.courseDetails.discount_percentage !== 0 && <span className="price-before-discount">{(this.state.courseDetails.price * +this.state.courseDetails.discount_percentage) / 100 + +this.state.courseDetails.price}</span>}
                                                    {this.state.courseDetails.price} KD 
                                                </p> : 
                                                "مجانية"}

                                                {this.state.courseDetails.discount_percentage == 0 ? null : (
                                                    <span className="course-descount">
                                                        {this.state.courseDetails.discount_percentage}٪ {this.state.courseDetails.discount_text}{" "}
                                                    </span>
                                                )}
                                            </ScrollAnimation>
                                        )}
                                    </Col>
                                    {/* end course price */}

                                    {/* course level */}

                                    <Col
                                        xl={3}
                                        //   lg={12}
                                        sm={6}
                                        className=" z-index-top padding-5px"
                                    >
                                        {this.state.courseLoader ? (
                                            <Skeleton height={150} />
                                        ) : (
                                            <ScrollAnimation animateIn="zoomIn" animateOnce={true} className="course-details" duration={2}>
                                                {/* <div className="course-details"> */}
                                                <img src="/img/pie-chart.png" alt="l" />
                                                <p> {this.state.courseDetails.level === 1 ? "مبتدئ" : this.state.courseDetails.level === 2 ? "متوسط" : "متقدم"}</p>
                                                <span>{this.state.courseDetails.level === 1 ? "هذه الدورة لا تطلب الخبرة المسبقة" : this.state.courseDetails.level === 2 ? "هذه الدورة  تتطلب القليل من الخبرة المسبقة" : "هذه الدورة تتطلب الخبرة المسبقة"}</span>
                                                {/* </div> */}
                                            </ScrollAnimation>
                                        )}
                                    </Col>
                                    {/* end course level */}

                                    {/* course type */}

                                    <Col xl={6} lg={12} md={6} sm={12} className=" z-index-top padding-5px">
                                        {this.state.courseLoader ? (
                                            <Skeleton height={150} />
                                        ) : (
                                            <ScrollAnimation animateIn="zoomIn" animateOnce={true} className="course-details course-details-special" duration={2}>
                                                {/* <div className="course-details course-details-special"> */}
                                                <div className="course-details-special-img">
                                                    <img src="/img/computer (1).png" alt="l" />
                                                </div>
                                                <div className="course-details-special-text">
                                                    <p> {this.state.courseDetails.type == 1 ? "تدريب اونلاين" : this.state.courseDetails.type == 2 ? "تدريب شخصي" : "تدريب مباشر"}</p>
                                                    <span> {this.state.courseDetails.type == 1 ? "مسجلة بجودة عالية" : this.state.courseDetails.type == 2 ? "" : " "}</span>
                                                </div>
                                                {/* </div> */}
                                            </ScrollAnimation>
                                        )}
                                    </Col>
                                    {/* end course level */}

                                    {/* course Degree */}

                                    <Col xl={6} md={6} lg={12} sm={12} className=" z-index-top padding-5px">
                                        {this.state.courseLoader ? (
                                            <Skeleton height={150} />
                                        ) : (
                                            this.state.courseDetails.certificate_name && (
                                                <ScrollAnimation animateIn="zoomIn" animateOnce={true} className="course-details course-details-special" duration={2}>
                                                    {/* <div className="course-details course-details-special"> */}
                                                    <div className="course-details-special-img">
                                                        <img src={this.state.courseDetails.certificate_icon === null ? "/img/medal.png" : Global.image_link + this.state.courseDetails.certificate_icon} alt="l" />
                                                    </div>
                                                    <div className="course-details-special-text">
                                                        <p>{this.state.courseDetails.certificate_name}</p>
                                                        <span>{this.state.courseDetails.certificate_text}</span>
                                                    </div>
                                                    {/* </div> */}
                                                </ScrollAnimation>
                                            )
                                        )}
                                    </Col>
                                    {/* end course Degree */}
                                </Row>
                            </Col>

                            {/* end  the next course */}

                            {/* course model video */}

                            <Col xl={4} lg={5} sm={12}>
                                {this.state.courseLoader ? (
                                    <Skeleton height={400} />
                                ) : this.state.courseDetails.video === null || (this.state.courseDetails.video == "" && this.state.courseDetails.video_link === null) || this.state.courseDetails.video_link === "" ? null : (
                                    <>
                                        <ScrollAnimation animateIn="zoomIn" animateOnce={true} className="queries-video" duration={2}>
                                            <img src={Global.image_link + this.state.courseDetails.video_image} alt="l" />
                                            <img onClick={() => this.setState({ isOpen: true })} src="/img/Group832.png" alt="l" />
                                        </ScrollAnimation>

                                        <ModalVideo
                                            channel="custom"
                                            autoplay
                                            isOpen={this.state.isOpen}
                                            url={this.state.courseDetails.video === null || this.state.courseDetails.video == "" ? this.state.courseDetails.video_link : Global.image_link + this.state.courseDetails.video}
                                            onClose={() => {
                                                this.setState({ isOpen: false });
                                            }}
                                        />
                                    </>
                                )}
                            </Col>
                            {/* end course model video */}

                            {/* points img  */}

                            <div className="queries-points-img">
                                <img src="/img/lastcut.png" alt="l" />
                            </div>
                            {/* end points img  */}
                        </Row>
                    </Container>

                    {/* course-circle  img  */}

                    <div className="queries-circle">
                        <img src="/img/Group831cut.png" alt="l" />
                    </div>
                    {/* end course-circle  img  */}
                </div>
                {/*   end courseDetails  section   */}

                {/* what you will learn in this course  section */}

                <div className="whatLearn-forWho">
                    <Container fluid>
                        <Row className="justify-content-center">
                            {/* main description */}
                            <Col lg={6}>
                                <div className="ourServices-paragraph">{this.state.courseLoader ? <Skeleton height={150} /> : <p>{this.state.courseDetails.descr}</p>}</div>
                            </Col>
                            {/* end main description */}
                        </Row>
                        <Row className="justify-content-center">
                            {/* for who this course */}
                            <Col xl={4} md={6} className=" z-index-top padding-5px">
                                {this.state.courseLoader ? (
                                    <Skeleton height={300} />
                                ) : (
                                    <ScrollAnimation animateIn="zoomIn" animateOnce={true} className="course-details" duration={2}>
                                        {/* <div className="course-details"> */}
                                        <img src="/img/group.png" alt="l" />
                                        <p>لمن هذه الدورة</p>
                                        <span>{this.state.courseDetails.course_target}</span>
                                        {/* </div> */}
                                    </ScrollAnimation>
                                )}
                            </Col>
                            {/* end for who this course */}

                            {/* what you will learn */}
                            <Col xl={4} md={6} className=" z-index-top padding-5px">
                                {this.state.courseLoader ? (
                                    <Skeleton height={300} />
                                ) : (
                                    <ScrollAnimation animateIn="zoomIn" animateOnce={true} className="course-details" duration={2}>
                                        {/* <div className="course-details"> */}
                                        <img src="/img/book(2).png" alt="l" />
                                        <p>سوف تتعلم في هذة الدورة</p>
                                        <span>{this.state.courseDetails.course_goals}</span>
                                        {/* </div> */}
                                    </ScrollAnimation>
                                )}
                            </Col>
                            {/* end what you will learn */}
                        </Row>
                    </Container>
                </div>

                {/* end what you will learn in this course  section */}

                {/* plan of course  */}

                <div className="planOfCourse" id="planOfCourse">
                    <div className="queries">
                        <Container fluid>
                            <Row className="justify-content-space-between">
                                {/*  personal training title  */}
                                <Col xs={12}>
                                    <ScrollAnimation animateIn="rotateInDownRight" animateOnce={true} duration={1}>
                                        <div className="ourServices-title">
                                            <img src="/img/book(1).png" alt="l" />
                                            <h3>مخطط الدورة</h3>
                                        </div>
                                    </ScrollAnimation>
                                </Col>
                                {/* end  personal training title  */}

                                {/*  accordion section */}

                                <Col lg={6} className="z-index-top">
                                    {this.state.courseLoader ? (
                                        <Skeleton height={300} />
                                    ) : (
                                        <div className="accordion-course">
                                            <Accordion defaultActiveKey="0">
                                                {this.state.courseSessions.map((item, index) => {
                                                    return (
                                                        <Card key={index}>
                                                            <Accordion.Toggle
                                                                as={Card.Header}
                                                                eventKey={`${index}`}
                                                                // ["nameSelected" + index]: valueLi.value_name,
                                                                className="watch-done"
                                                            >
                                                                <div>
                                                                    <img src="/img/Ellipse98.png" alt="1" />
                                                                    <h4>{item.name}</h4>
                                                                </div>
                                                                <div>
                                                                    {/* <span>تم انهاء المشاهدة</span> */}
                                                                    <span>{item.start_date}</span>
                                                                    <img src="/img/Path164.png" alt="1" />
                                                                </div>
                                                            </Accordion.Toggle>
                                                            {item.content.map((child, indexChild) => {
                                                                return (
                                                                    <Accordion.Collapse eventKey={`${index}`} key={indexChild}>
                                                                        <Card.Body>
                                                                            <p
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        videoUrl: child.video == null || child.video == "" ? child.video_link : child.video,
                                                                                    });
                                                                                }}
                                                                            >
                                                                                {child.name}
                                                                            </p>
                                                                            <span>{child.start_date}</span>
                                                                        </Card.Body>
                                                                    </Accordion.Collapse>
                                                                );
                                                            })}
                                                        </Card>
                                                    );
                                                })}
                                            </Accordion>
                                        </div>
                                    )}
                                </Col>
                                {/* end  accordion section */}

                                {/*  course video */}

                                <Col lg={6} sm={12}>
                                    {this.state.courseLoader ? (
                                        <Skeleton height={300} />
                                    ) : (
                                        <ScrollAnimation animateIn="swing" animateOnce={true} className="queries-video" duration={2}>
                                            {/* <div className="queries-video"> */}
                                            <ReactPlayer url={this.state.videoUrl} controls={true} className="courseVideo" />
                                            {/* </div> */}
                                        </ScrollAnimation>
                                    )}
                                </Col>
                                {/* end  course video */}
                            </Row>
                        </Container>

                        {/* course-circle  img  */}

                        <div className="queries-circle">
                            <img src="/img/Group831cut.png" alt="l" />
                        </div>
                        {/* end course-circle  img  */}
                    </div>
                </div>
                {/* end plan of course  */}
            </div>
        );
    }
}
export default CourseDetails;
